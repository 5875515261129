import React, { useContext, useEffect, useDebugValue, useState } from 'react';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { NavContext } from "../../providers/nav_provider";
import { Row, Button, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPlusCircle, faEdit } from '@fortawesome/free-solid-svg-icons'
import SortableItem from "../common/sortable_item";
import WorkoutExerciseItem from "./workout_exercise_item";
import GiphyButton from '../common/giphy_button';
import WorkoutExerciseEditModal from "../modals/workout_exercise_edit_modal";
import ExerciseModal from "../modals/exercise_modal";
import useStateWithLabel from '../../utils/use_state_with_label';

export default function WorkoutExerciseList(props) {
    const { exercises, handleAddExercises, isOwnWorkout, mode, setIsDeepEditMode,
        setExercises, setWorkoutPerform, userCanEdit, workout, workoutPerform } = props;

    const { setDirty } = useContext(NavContext);

    const [activeId, setActiveId] = useState(null);
    const [currentExercise, setCurrentExercise] = useState(null);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    const [isGiphyView, setIsGiphyView] = useState(false);
    const [isSortMode, setIsSortMode] = useState(null);
    const [showExerciseList, setShowExerciseList] = useState(true);
    const [showExerciseModal, setShowExerciseModal] = useStateWithLabel(false, 'showExerciseModal');

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        const storedPreference = localStorage.getItem('prefersWorkoutGiphyView');

        if (storedPreference) {
            setIsGiphyView(JSON.parse(storedPreference));
        }
    }, [])

    useEffect(() => {
        if (currentExercise !== null && currentExerciseIndex !== null) {
            setShowExerciseModal(true);
        }

    }, [currentExercise, currentExerciseIndex, setShowExerciseModal])

    if (!exercises) { return null; }

    const handleCloseModal = () => {    
        setCurrentExercise(null);
        setCurrentExerciseIndex(null);
        setShowExerciseModal(false);
    }

    const handleDragStart = (event) => {
        const { active } = event;

        setActiveId(active.id);
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active?.id && over?.id && (active.id !== over.id)) {
            setExercises((exercises) => {
                const oldIndex = exercises.findIndex(exercise => exercise.workout_exercise_id === active.id);
                const newIndex = exercises.findIndex(exercise => exercise.workout_exercise_id === over.id);

                return arrayMove(exercises, oldIndex, newIndex);
            });

            if (Array.isArray(workoutPerform)) {
                setWorkoutPerform((workoutPerform) => {
                    const oldIndex = workoutPerform.findIndex(exercise => exercise.workout_exercise_id === active.id);
                    const newIndex = workoutPerform.findIndex(exercise => exercise.workout_exercise_id === over.id);

                    return arrayMove(workoutPerform, oldIndex, newIndex);
                });
            }
        }
    }

    const handleExerciseClearSync = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        if (newWorkoutPerform.exercises && newWorkoutPerform.exercises[currentExerciseIndex]) {
            newWorkoutPerform.exercises[currentExerciseIndex].start = undefined;
        }
        setWorkoutPerform(newWorkoutPerform);
    };

    const handleExerciseDeleteClick = (index) => {
        let newExercises = [...exercises];
        newExercises.splice(index, 1);

        let newExercisePerforms = [...workoutPerform.exercises]
        newExercisePerforms.splice(index, 1);

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.exercises = newExercisePerforms;

        setExercises(newExercises);
        setDirty(true);
        setWorkoutPerform(newWorkoutPerform);
    };

    // change this to duplicate the item at the index, and add it immediately after the index
    const handleExerciseDuplicateClick = (index, options) => {
        const currentExercise = exercises[index];
        const currentExercisePerform = (workoutPerform.exercises && workoutPerform.exercises[index]) ? workoutPerform.exercises[index] : {};

        let newExercises = [...exercises];
        newExercises.push(currentExercise);
        let newWorkoutPerform = Object.assign({}, workoutPerform)
        newWorkoutPerform.exercises = [...workoutPerform.exercises];
        newWorkoutPerform.exercises.push(currentExercisePerform);

        setDirty(true);
        setExercises(newExercises);
        setWorkoutPerform(newWorkoutPerform);
    };

    const handleExerciseClick = (index, options) => {
        // If is editing, player must be playing to set time
        if (workout && workout.synced && (mode === 'show' || (((mode === 'edit') || (mode === 'new')) && isVideoPlaying))) {
            const startTime = Number(workoutPerform.exercises[index].start);

            if (!isNaN(startTime)) {
                if (reactPlayerRef?.current) {
                    reactPlayerRef.current.seekTo(startTime);
                }
            }
            setMainPlayerSecs(startTime);
        } else {
            setCurrentExerciseIndex(index);
            setCurrentExercise(exercises[index]);
        }
    };

    const handleExerciseImageClick = (index) => {
        setCurrentExerciseIndex(index);
        setCurrentExercise(exercises[index]);
    };

    const handleExerciseSyncButtonClick = (index) => {

        // ToDo: do we clear round, rest, etc?
        let newWorkoutPerform = Object.assign({}, workoutPerform);
        if (newWorkoutPerform.exercises[index].start !== undefined) {
            newWorkoutPerform.exercises[index] = Object.assign({}, newWorkoutPerform.exercises[index], { start: undefined })
        } else {
            newWorkoutPerform.exercises[index] = Object.assign({}, newWorkoutPerform.exercises[index], { start: mainPlayerSecs.toFixed(1) })
        }

        setWorkoutPerform(newWorkoutPerform);
    };

    const handleExerciseDuplicateSynced = (index) => {
        let newWorkoutPerform = Object.assign({}, workoutPerform);
        let newExercises = [...exercises];
        newWorkoutPerform.exercises.push(Object.assign({}, newWorkoutPerform.exercises[index], { start: mainPlayerSecs.toFixed(1) }));
        newExercises.push(Object.assign({}, exercises[index]));

        setExercises(newExercises);
        setWorkoutPerform(newWorkoutPerform);
    };

    const handleGiphyButtonClick = (event) => {
        event.preventDefault();

        const newIsGiphyView = !isGiphyView;

        setIsGiphyView(newIsGiphyView);
        localStorage.setItem('prefersWorkoutGiphyView', newIsGiphyView ? 'true' : 'false');
    };

    // select exercise from exercises based on matching id field
    const activeExercise = () => {
        return exercises.find(exercise => exercise.workout_exercise_id === activeId);
    }

    const itemClasses = (index) => {
        // ToDo: include selected if index (or id?) is in selectedItems
        // selectedItems.includes(index) ? 'selected bg-secondary' : 'bg-secondary';
        let classes = (index % 2) ? '' : 'bg-secondary';
        return classes
    }

    const renderDropdownAddButton = () => {
        const plusIcon = <span><FontAwesomeIcon icon={faPlusCircle} /></span>;
        if (userCanEdit && (mode === 'show')) {
            return (
                <NavDropdown.Item href="#" onClick={(event) => handleAddExercises(event)}>
                    <div className="d-flex justify-content-between"><span>Add More...</span>{plusIcon}</div>
                </NavDropdown.Item>
            );
        }
    }

    const renderDropdownSortButton = () => {
        if (['new', 'edit'].includes(mode) && (exercises?.length > 0)) {
            const sortIcon = <span><FontAwesomeIcon icon={faEdit} /></span>;

            return (
                <NavDropdown.Item onClick={(event) => {
                    event.preventDefault();
                    setIsSortMode(true);
                    setIsDeepEditMode(true);
                    setIsGiphyView(false);
                    setShowExerciseList(true);
                }}>
                    <div className="d-flex justify-content-between"><span>Sort</span>{sortIcon}</div>
                </NavDropdown.Item>
            )
        }
    }

    const renderDropdownGiphyButton = () => {
        return (
            <NavDropdown.Item href="#" onClick={(event) => {
                setShowExerciseList(true);
                handleGiphyButtonClick(event);
            }}>
                <GiphyButton iconColor="white" isGiphyView={isGiphyView} classes="d-flex justify-content-between text-light align-items-center" showLabel={true} />
            </NavDropdown.Item>
        )
    }

    /* ToDo: add this back in
        const linkedIcon = <FontAwesomeIcon className="pe-2" icon={faLink} />;
        {workout.synced && linkedIcon}
    */
    const renderExerciseListOptions = () => {
        let ellipsisIcon = <span className="ellipsis-icon" style={{ fontSize: '1.5rem' }}><FontAwesomeIcon icon={faEllipsis} /></span>;

        return (
            <Navbar variant="dark" bg="dark" className="w-100">
                <Container fluid className="px-2">
                    <Nav.Item className="ms-1 text-light" href="#">Exercises</Nav.Item>
                    {isSortMode &&
                        <Nav.Item>
                            <Button variant='primary' size="sm"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setIsSortMode(false);
                                    setIsDeepEditMode(false);
                                }}>Done</Button>
                        </Nav.Item>
                    }
                    {!isSortMode &&
                        <NavDropdown title={ellipsisIcon} id="exercise-list-dropdown" align="start" bg="dark"
                            data-bs-theme="dark" className="text-light dropdown-dark">
                            {renderDropdownAddButton()}
                            {(exercises?.length > 1) && renderDropdownSortButton()}
                            {renderDropdownGiphyButton()}
                        </NavDropdown>
                    }
                    <Navbar.Toggle type="button" style={{ display: 'flex' }} aria-label="Toggle navigation" className="dropleft" bsPrefix="sws-navbar-toggler"
                        onClick={() => setShowExerciseList(!showExerciseList)} >
                        <div className="d-flex justify-content-between">
                            <div className="navbar-toggler-icon"></div>
                        </div>
                    </Navbar.Toggle>
                </Container>
            </Navbar>
        );
    }

    const renderExerciseModals = () => {
        return (
            <>
                {(mode === 'show') &&
                    <ExerciseModal {...props}
                        exercise={currentExercise}
                        exerciseIndex={currentExerciseIndex}
                        handleCloseModal={handleCloseModal}
                        hideAddToWorkout={isOwnWorkout}
                        key="exercise-modal"
                        workout={workout}
                        showExerciseModal={showExerciseModal}
                    />
                }
                {(mode === 'edit') && <WorkoutExerciseEditModal {...props}
                    exercise={currentExercise}
                    exerciseIndex={currentExerciseIndex}
                    handleCloseModal={handleCloseModal}
                    handleDeleteButton={() => {
                        handleExerciseDeleteClick(currentExerciseIndex)
                    }}
                    handleClearSyncButton={handleExerciseClearSync}
                    key="workout-exercise-modal"
                    showExerciseModal={showExerciseModal}
                    workout={workout}
                />}
            </>
        )
    }

    const sortableWrapperClasses = (isActive) => {
        let classes = isGiphyView ? 'p-0 col-xl-3 col-md-4 col-6' : 'list-group-item row';
        classes = isActive ? `${classes} bg-secondary` : classes;

        return classes;
    }

    const dndLayout = () => {
        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}>
                <SortableContext items={exercises} strategy={isGiphyView ? rectSortingStrategy : verticalListSortingStrategy}>
                    {exercises.map((exercise, index) => (
                        <SortableItem id={exercise.workout_exercise_id}
                            classes={sortableWrapperClasses()}
                            disabled={!isSortMode}
                            handleSortDisabledClick={handleExerciseClick}>
                            <WorkoutExerciseItem {...props}
                                classes={itemClasses(index)}
                                exercise={exercise}
                                exercises={exercise.segments}
                                handleExerciseClick={handleExerciseClick}
                                handleExerciseDeleteClick={handleExerciseDeleteClick}
                                handleExerciseDuplicateClick={handleExerciseDuplicateClick}
                                handleExerciseImageClick={handleExerciseImageClick}
                                handleExerciseSyncButtonClick={handleExerciseSyncButtonClick}
                                handleExerciseDuplicateSynced={handleExerciseDuplicateSynced}
                                index={index}
                                isGiphyView={isGiphyView}
                                isSortMode={isSortMode}
                                key={exercise.workout_exercise_id}
                                showWorkoutAttrs={true} />
                        </SortableItem>
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeId &&
                        <div className={sortableWrapperClasses(true)}>
                            <WorkoutExerciseItem {...props} exercise={activeExercise()} />
                        </div>
                    }
                </DragOverlay>
            </DndContext>
        )
    }

    return (
        <>
            {renderExerciseListOptions()}
            <Navbar.Collapse in={showExerciseList}>
                {isGiphyView && <Row>{dndLayout()}</Row>}
                {!isGiphyView &&
                    <div className="items exercises">
                        {dndLayout()}
                    </div>
                }
            </Navbar.Collapse>
            {renderExerciseModals()}
        </>
    )

    /* ToDo: bring this back:
            {
                multiSelect &&
                <NavGroupingButtons
                    {...props}
                    setSelectedExercises={setSelectedItems}
                />
            }
    */
}
