import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { SwsAnalytics } from "../../services/sws_analytics";
import { Row, Button, Modal, Form, Col } from 'react-bootstrap';
import ExerciseModalRenderer from "./exercise_modal_renderer";
import GroupExerciseModalRenderer from "./group_exercise_modal_renderer";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AddButton from "../common/add_button";
import 'react-alice-carousel/lib/alice-carousel.css';
import PerformUtils from "../../utils/perform_utils";
import UserAvatar from '../common/user_avatar';
import * as Sentry from "@sentry/browser";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../utils/config_context";
import WorkoutSelection from "./workout_selection";
import ModalFooter from "./modal_footer";
import { UserContext } from "../../providers/user_provider";

// Description: https://docs.google.com/document/d/14f4I3X9fU8jHgFpFzj9OfZFTmqjmR06hMDMra_cFwjc/edit
//
// Flow Diagram: https://docs.google.com/drawings/d/1irEcVpbGoQdZfVPfX4_zVbUTk_3U5ry8V5pUDpydhPo/edit

export default function ExerciseModal(props) {
    const {
        categoryProp, currentGroupExercise, exercise,
        exerciseIndex, handleCloseModal, handleFeature, handleLoggedOutAddWorkout, hideAddToWorkout, mode,
        showExerciseModal, workout, workoutPerform
    } = props;

    const { currentOrLastWorkout, currentWorkout, setCurrentWorkout, user } = useContext(UserContext);

    // When invoked by ExerciseList (part of a workout), props.workoutPerform are manipulated and used as is.
    // They may be saved later when the whole workout is saved.

    // When invoked by TopList, props.workoutPerform are copied to state.localWorkoutPerform where
    // they may be manipulated and saved.

    //     uri:  /exercises
    //           /exercises/:exercise_id/exercises
    //           exercisePerform => for use by add exercise to workout
    //
    //
    //     uri:  /workouts/:workoutId/edit
    //           exercisePerform => manipulated before saving
    //           exerciseIndex - the index of the exercise being referenced in workoutPerform
    const config = useContext(ConfigContext);
    const { categoryParam } = useParams();

    const category = categoryProp || categoryParam;

    const [exercisePerform, setExercisePerform] = useState({});
    const [newGroupExerciseTitle, setNewGroupExerciseTitle] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    // 'none', 'createGroup', 'addToExercise', 'addToWorkout', 'done'
    const [saveMode, setSaveMode] = useState(null);

    const navigate = useNavigate();
    const groupExerciseId = useParams().groupExerciseId;
    const showFeatureButton = user && user.is_admin && handleFeature;
    const showSuperSetButton = (category === 'exercises') && !groupExerciseId && (saveMode === 'none');
    const showAddToWorkoutButton = !user?.id;

    useEffect(() => {
        if (exerciseIndex !== null && workoutPerform?.exercises?.[exerciseIndex]) {
            const newExercisePerform = workoutPerform.exercises[exerciseIndex];

            setExercisePerform(newExercisePerform);
        }
        return (() => {
            setExercisePerform(null);
        })

    }, [workoutPerform?.exercises, exerciseIndex]);

    useEffect(() => {
        if (groupExerciseId) {
            setSaveMode('addToExercise');
        } else {
            setSaveMode('none');
        }
    }, [mode, groupExerciseId, showExerciseModal])

    const isFormValid = () => {
        if (saveMode === 'createGroup' && newGroupExerciseTitle?.length < 4) return false;

        return true;
    };

    // ToDo: do we even need this since we have WorkoutExerciseEditModal?

    const handleAddExerciseToWorkoutButton = () => {
        let newExercisePerform = {};

        if ((exercise.is_group) && (currentWorkout?.workout_type === 'timed')) {
            const numSegments = exercise.segments.length;

            newExercisePerform = Object.assign({},
                PerformUtils.performFromDefaults(currentWorkout.workoutPerform),
                { exercises: Array(numSegments).fill({}) });
        }

        setExercisePerform(newExercisePerform);
        setSaveMode('addToWorkout');
    };

    const handleAddButtonCallback = () => {
        if ((exercise.is_group) && (currentWorkout?.workout_type === 'timed')) {
            let newExercisePerform = {};
            const numSegments = exercise.segments.length;

            newExercisePerform = Object.assign({},
                PerformUtils.performFromDefaults(currentWorkout.workoutPerform),
                { exercises: Array(numSegments).fill({}) });

            setExercisePerform(newExercisePerform);
        }
    }

    const handleAddExerciseToWorkout = (event) => {
        if (event) {
            event.preventDefault();
        }

        const targetWorkout = currentOrLastWorkout();

        if (targetWorkout?.id === null) {
            Sentry.captureEvent("No workoutId defined in ExerciseModal:handleAdddExerciseToWorkout");
            return;
        }

        setIsSaving(true);

        let data = {
            exercise_id: exercise.id,
            perform: exercisePerform
        }

        API.post(`${config.apiBase}/workouts/${targetWorkout.id}/add_exercise`, JSON.stringify(data)).then(result => {
            SwsAnalytics.event({
                category: 'Workout',
                action: 'Add Exercise'
            });
            setCurrentWorkout(targetWorkout);
            handleDone();
        }
        )
            .catch(error => {
                setIsSaving(false);
                alert(error.message);
            });
    };

    const handleAddExerciseToGroupExercise = () => {
        if (!currentGroupExercise?.id) return;

        setIsSaving(true);

        API.post(`${config.apiBase}/exercises/${currentGroupExercise.id}/add_segment`,
            JSON.stringify({ segment_id: exercise.id }))
            .then(result => {
                SwsAnalytics.event({
                    category: 'Exercise',
                    action: 'Add Segment'
                });
                handleDone();
            }
            )
            .catch(error => {
                setIsSaving(false);
                alert(error.message);
            });
    };

    const handleChangeGroupTitle = (event) => {
        setNewGroupExerciseTitle(event.target.value);
    };

    const handleCreateGroupExercise = () => {
        if (isSaving) return;
        if (!isFormValid()) return;

        setIsSaving(true);

        // ToDo: copy tags from parent exercise
        let exerciseData = {
            is_group: true,
            title: newGroupExerciseTitle,
            segment_ids: [exercise.id]
        };

        API.post(`${config.apiBase}/exercises`, JSON.stringify(exerciseData))
            .then(result => {
                handleNavigateToGroupExQuickAdd(result);
            })
            .catch(error => {
                alert(error.message);
                setIsSaving(false);
            });
    };

    const handleCreateWorkout = () => {
        navigate('/workouts/new');
    };

    const handleDone = () => {
        setIsSaving(false);
        setSaveMode('none');
        setNewGroupExerciseTitle('');
        handleCloseModal();
    };

    const handleDetail = () => {
        navigate(`/exercises/${exercise.id}`);
    };

    const handleNavigateToGroupExQuickAdd = () => {
        navigate(`/exercises/${exercise.id}/add`);
        handleCloseModal();
    };

    const renderAddToWorkoutSection = () => {
        if (!user?.id || !user.numWorkouts) {
            return null;
        }

        return (
            <>
                <WorkoutSelection
                    {...props}
                    addButtonCallback={handleAddButtonCallback}
                    handleAddToWorkout={handleAddExerciseToWorkoutButton}
                    isSaving={isSaving}
                    saveMode={saveMode}
                    setSaveMode={setSaveMode}
                    workout={workout}
                />
                {groupExerciseId && !exercise.is_group &&
                    <>
                        {(saveMode === 'none') &&
                            <Form.Group>
                                <Row>
                                    <Col xs={4}>
                                        <Button variant="primary" size="sm"
                                            onClick={handleAddExerciseToGroupExercise}
                                            className="btn-add-exercise">
                                            Add to:
                                        </Button>
                                    </Col>
                                    <Col xs={8}>
                                        {currentGroupExercise?.title}
                                    </Col>
                                </Row>
                            </Form.Group>
                        }
                        {(saveMode !== 'none') &&
                            <div className="d-flex w-100 text-center">
                                Adding to {currentGroupExercise?.title}
                            </div>
                        }
                    </>
                }
            </>
        );
    };

    const renderFooterPrefixButtons = () => {
        const starClasses = exercise.is_featured ? 'warning' : 'secondary';
        const star = <div className='icon-margin'><FontAwesomeIcon icon={faStar} /></div>;

        return (
            <>
                {showFeatureButton &&
                    <Button variant={starClasses}
                        className="btn-sm"
                        onClick={(event) => handleFeature(event)}>
                        {star}
                    </Button>
                }

                {showAddToWorkoutButton &&
                    <AddButton classes="btn-sm" clickHandler={handleLoggedOutAddWorkout} label="Add to Workout" />
                }

                {showSuperSetButton &&
                    <Button variant="primary" size="sm"
                        onClick={() => {
                            setSaveMode('createGroup');
                        }}>
                        Create Superset...
                    </Button>
                }
            </>
        );
    }

    const renderNewGroupTitle = () => {
        return (
            <Form.Group>
                <Form.Control required type="text" name="title" placeholder="Superset Title"
                    onChange={handleChangeGroupTitle}
                    value={newGroupExerciseTitle || ''} />
            </Form.Group>
        );
    };

    const renderSaveDoneButton = () => {
        let saveLabel;
        let variant = 'primary';

        if (saveMode === 'none') return null;

        if (isSaving) {
            saveLabel = 'Saving...';
        } else if (saveMode === 'done') {
            saveLabel = 'Done';
        } else if (saveMode === 'addToWorkout') {
            saveLabel = 'Add to Workout';
            variant = 'success';
        } else if (saveMode === 'addToExercise') {
            saveLabel = 'Add to Exercise';
            variant = 'success';
        } else if (saveMode === 'createGroup') {
            saveLabel = 'Create';
            variant = 'success';
        }

        return (<Button variant={variant} size="sm" disabled={!isFormValid()}
            onClick={() => {
                if (isSaving) return;

                if (!user?.id) {
                    handleLoggedOutAddWorkout();
                }

                switch (saveMode) {
                    case 'createGroup':
                        handleCreateGroupExercise();
                        break;
                    case 'addToExercise':
                        handleDone();
                        break;
                    case 'addToWorkout':
                        handleAddExerciseToWorkout();
                        break;
                    default:
                        break;
                }

            }}>{saveLabel}</Button>
        )
    };

    // ToDo: simplify adding exercises to workouts, or provide all options?
    // Since the "+" is the simple way, why not have the exercise modal be more complicated?
    //    But then, you have to handle the exercises => workout add situation.
    //    Should we set the workout added to as the "global context" (/workouts/workoutId/exercises) ?

    if (exercise === null || exercise === undefined) return null;

    const showPrefixButtons = showFeatureButton || showSuperSetButton || showAddToWorkoutButton;

    return (
        <Modal id="exercise-modal" animation={false} key="exercise-modal-inner" show={showExerciseModal}
            onHide={() => {
                handleDone()
            }}>
            <Modal.Header>
                <Modal.Title>{exercise.title}</Modal.Title>
                <div className="w-100">
                    <UserAvatar mode={mode} object={exercise} />
                </div>
                <Button className="btn-close" data-bs-dismiss="modal"
                    onClick={() => {
                        handleDone()
                    }} />
            </Modal.Header>
            <Modal.Body>
                {exercise.reference_link &&
                    <div className="reference w-100 text-center">
                        Ref: <a href={exercise.reference_link} target="_blank"
                            rel="noreferrer">{exercise.reference_link}</a>
                    </div>
                }
                <div className="py-2 d-flex w-100 justify-content-between">
                    {user?.id && (user?.lastWorkouts?.length === 0) &&
                        <Button variant="success" size="sm" onClick={() => {
                            handleCreateWorkout()
                        }}>Create a Workout</Button>
                    }
                </div>
                {!hideAddToWorkout && renderAddToWorkoutSection()}
                {(saveMode === 'createGroup') && renderNewGroupTitle()}
                {!exercise.is_group && <ExerciseModalRenderer
                    {...props}
                    active={showExerciseModal}
                    exercisePerform={exercisePerform}
                    saveMode={saveMode}
                    setExercisePerform={setExercisePerform}
                    workout={(category === 'workouts') ? workout : currentWorkout} />
                }
                {exercise.is_group && <GroupExerciseModalRenderer
                    {...props}
                    active={showExerciseModal}
                    exerciseIndex={exerciseIndex}
                    exercisePerform={exercisePerform}
                    saveMode={saveMode}
                    setExercisePerform={setExercisePerform}
                    workout={(category === 'workouts') ? workout : currentWorkout} />
                }
            </Modal.Body>
            <ModalFooter
                handleDetail={handleDetail}
                renderPrefixButtons={showPrefixButtons && renderFooterPrefixButtons}
                renderSaveDoneButton={renderSaveDoneButton}
                saveMode={saveMode}
            />
        </Modal>
    );
}