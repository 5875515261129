import React  from 'react';
import {Col} from "react-bootstrap";

const ProfileImageWrapper = (props) => {
    const {children, index} = props;

    return (
        <Col xs={6} className="d-flex align-items-center" key={`piw-${index}`}>
            <div className="card bg-dark text-white">
                {children}
            </div>
        </Col>
    );
}

export default ProfileImageWrapper;