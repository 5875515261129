import React, { useContext, useDebugValue, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Col, Form, FormControl } from 'react-bootstrap';
import ObjectDefaults from "../utils/object_defaults";
import InfiniteScroll from 'react-infinite-scroll-component';
import API from '../utils/api';
import debounce from 'lodash.debounce';
import ExerciseListItem from './exercises/exercise_list_item';
import WorkoutItem from './workouts/workout_item';
import ExternalMediaItem from './external_media/external_media_item';
import ExerciseModal from "./modals/exercise_modal";
import ExternalMediaModal from "./modals/external_media_modal";
import Formatter from '../utils/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from "./common/delete_icon";
import FiltersModal from './modals/filters_modal';
import WorkoutShareItem from "./workouts/workout_share_item";
import GiphyButton from './common/giphy_button';
import queryString from "query-string";
import * as Sentry from "@sentry/browser";
import HeartbeatImg from "./common/heartbeat_img";
import { ConfigContext } from "../utils/config_context";
import useSWR from "swr";
import { SwrUtils } from "../utils/swr_utils";
import { SwsAnalytics } from "../services/sws_analytics";
import { NavContext } from "../providers/nav_provider";
import { UserContext } from "../providers/user_provider";
import { SearchContext } from '../providers/search_provider';
import FilterSelectionButton from './common/filter_selection_button';
import useStateWithLabel from '../utils/use_state_with_label';

export default function Search(props) {
    const { categoryProp } = props;
    const { setAfterLoginUrl, setAlert } = useContext(NavContext);
    const { isFilterSelected, searchTerm, setSearchTerm } = useContext(SearchContext);
    const { currentWorkout, setCurrentWorkout, user } = useContext(UserContext);

    // SUR: I chose to make groupExerciseId a param because it seems ephemeral, whereas the currentWorkout
    // seems to make more sense as a context variable, since the user might create a group exericse, within
    // the process of editting a workout.
    let { groupExerciseId, categoryParam } = useParams();

    const category = categoryProp || categoryParam;

    const listClass = `top-component search ${category}`;

    const config = useContext(ConfigContext);
    let navigate = useNavigate();
    let location = useLocation();
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [currentExercise, setCurrentExercise] = useState(null);
    // notLoaded, loading, loading, reloading, error
    const [currentGroupExercise, setCurrentGroupExercise] = useState(null);

    // What happens to the Url when a search is made in this quick add mode?

    // This is confusing, as ExerciseModal assumes the existence of setCurrentWorkout, but in the context
    // of /workouts/:workout_id, it will not have a current workout

    const [currentMedia, setCurrentMedia] = useState(null);
    const [showExerciseModal, setShowExerciseModal] = useState(false);
    const [showExternalMediaModal, setShowExternalMediaModal] = useState(false);
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [searchResults, setSearchResults] = useState({
        hasMore: true,
        items: [],
        totalPages: 1,
        totalEntries: 1
    });

    // This is a "global to search" variable that allows search facets to bump the searchCacheIndex
    // in order to force a reload, even if all of the url query parameters didn't change
    const [currentPage, setCurrentPage] = useStateWithLabel(1, 'currentPage');
    const [searchPath, setSearchPath] = useState('');
    const [searchQuery, setSearchQuery] = useState(null);
    const [searchBarInFocus, setSearchBarInFocus] = useState(false);
    const [isGiphyView, setIsGiphyView] = useState(true);

    const [formQ, setFormQ] = useState('');

    const workout = ObjectDefaults.workout();

    const formRef = useRef(null);

    // Initialization sets formQ from the location.search.q parameter
    // UseEffect watches for changes to location.search, and (re)runs an API call
    //
    // FormControl must use defaultValue as formQ (not value, otherwise its an infinite loop)
    //
    // handleChangeDebounced calls handleChange with the event.value.target, but doesn't prevent
    // the value from being updated in the textField
    //
    // handleChange changes the location.search.q value
    // The first useEffect gets retriggered off this changing of location.search
    //
    // Reference: https://dmitripavlutin.com/react-throttle-debounce/

    useEffect(() => {
        if (location.pathname === '/external_media/new') {
            setShowExternalMediaModal(true);
        } else if (location.pathname === '/search/external_media') {
            setShowExternalMediaModal(false);
        }

    }, [location.pathname]);

    // Reset the currentWorkout if groupExerciseId exists
    useEffect(() => {
        if (groupExerciseId) {
            setCurrentWorkout(null);
        }

    }, [groupExerciseId, setCurrentWorkout])

    // If user is not admin or owner of groupExercise, then navigate to base search
    useEffect(() => {
        if (currentGroupExercise && currentGroupExercise.user_id !== user?.id && !user?.is_admin) {
            navigate('/search/exercises');
        }
    }, [groupExerciseId, currentGroupExercise, navigate, user?.id, user?.is_admin])

    // Fetch and set currentGroupExercise
    const { } = useSWR((config.apiBase && groupExerciseId) ? `${config.apiBase}/exercises/${groupExerciseId}` : null,
        SwrUtils.authFetcher, {
        ...SwrUtils.stdOptions, onSuccess: (data) => {
            setCurrentGroupExercise(data);
        }
    });

    console.log(`Search: searchPath: ${searchPath}, searchQuery: ${searchQuery}, currentPage: ${currentPage}`);
    const { error, isLoading, mutate } = useSWR(
        (config.apiBase && searchPath && currentPage) ? `${config.apiBase}${searchPath}?${searchQuery}&current_page=${currentPage}` : null,
        SwrUtils.authFetcher, {
        ...SwrUtils.stdOptions, onSuccess: (data) => {
            handleData(data);
        }
    });

    // This gets triggered when the pathname or search change.
    // It should not handle pagination
    useEffect(() => {
        let params = '';

        if (location.search) {
            const queryParams = new URLSearchParams(location.search);
            const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
            let newSearchTerm = searchTerm;

            setFormQ(decodeURIComponent(queryParams.get('q') || ''));

            if (parsedQuery['q']) {
                newSearchTerm = queryParams.get('q');
                params += `search=${newSearchTerm}`;
            }

            if (queryParams.get('user_slug')) {
                params += `&user_slug=${queryParams.get('user_slug')}`;
            }

            if (parsedQuery['tags']?.length) {
                if (typeof parsedQuery['tags'] === 'string') {
                    params += `&tags=${parsedQuery['tags']}`;
                } else {
                    params += `&tags=${parsedQuery['tags'].join(',')}`;
                }
            }

            if (parsedQuery['em']?.length) {
                if (typeof parsedQuery['em'] === 'string') {
                    params += `&em=${parsedQuery['em']}`;
                } else {
                    params += `&em=${parsedQuery['em'].join(',')}`;
                }
            }

            if (parsedQuery['wt']) {
                params += `&${queryParams.get('wt')}=1`;
            }

            ['min', 'max', 'audio', 'live', 'other', 'playlist', 'wnull', 'wman', 'wt', 'wyt', 'wem'].forEach(param => {
                if (parsedQuery[param]) {
                    params += `&${param}=${queryParams.get(param)}`;
                }
            })

            if ((!groupExerciseId) && (parsedQuery['group'] === '1')) {
                params += `&group=1`;
            }
        } else {
            setFormQ('');
        }

        // Make sure we bust the cache: although the searchQuery may not have changed
        setSearchResults({
            hasMore: true,
            items: [],
            totalPages: 1,
            totalEntries: 1
        });
        setSearchPath(`/${category}`);
        console.log(`params: -${params}-`);
        setSearchQuery(params);

    }, [category, groupExerciseId, location.search, searchTerm, setSearchTerm]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchPath, searchQuery, setCurrentPage]);

    useEffect(() => {
        const storedPreference = localStorage.getItem('prefersExerciseGiphyView')
        if (storedPreference) {
            setIsGiphyView(JSON.parse(storedPreference));
        }
    }, []);

    useEffect(() => {
        if (isGiphyView) {
            localStorage.setItem('prefersExerciseGiphyView', 'true');
        } else {
            localStorage.setItem('prefersExerciseGiphyView', 'false');
        }
    }, [isGiphyView])


    const handleChange = (event) => { /* eslint-disable-line */
        // When Q changes, reset page to 1
        setCurrentPage(1);

        let queryParams = new URLSearchParams(location.search);
        queryParams.set('q', Formatter.fixedEncodeURIComponent(event.target.value));
        const newQuery = queryParams.toString().trim();

        if (newQuery.length) {
            navigate(`${location.pathname}?${newQuery}`);
        } else {
            navigate(location.pathname);
        }
    };

    const handleChangeDebounced = useMemo(
        () => debounce(handleChange, 500)
        , [handleChange]);

    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            handleChangeDebounced.cancel();
        }
    }, [handleChangeDebounced]);

    const handleClearCurrentWorkout = (event) => {
        event.preventDefault();
        setCurrentWorkout(null);
    };

    const handleClearGroupExerciseAddMode = (event) => {
        event.preventDefault();

        let newPathname = location.pathname.replace(/\/exercises\/[0-9]+\/add/, '/exercises');

        if (location.search.length) {
            navigate(`${newPathname}?${location.search}`);
        } else {
            navigate(newPathname);
        }
    };

    const handleSetShowExternalMediaModal = (wasSaved) => {

        setCurrentMedia(null);
        if (wasSaved && location.search.length) {
            let forwardPath = { pathname: location.pathname.replace(/\/new/, '') };
            forwardPath['search'] = `?${location.search}`;
            navigate(forwardPath);
        }

        setShowExternalMediaModal(false);
    }

    const handleData = (data) => {
        if (data) {
            if (data[`paged_${category}`]) {
                const serverCurrentPage = data[`paged_${category}`].state.current_page;
                const totalPages = data[`paged_${category}`].state.total_pages;
                const totalEntries = data[`paged_${category}`].state.total_entries;
                const hasMore = serverCurrentPage < totalPages;
                let myItems = data[`paged_${category}`][category] ? data[`paged_${category}`][category] : [];

                setSearchResults({
                    hasMore: hasMore,
                    items: searchResults.items.concat(myItems),
                    totalPages: totalPages,
                    totalEntries: totalEntries
                });
                setCurrentPage(Number(serverCurrentPage));

            } else {
                let myItems = (data.state.current_page > 1) ? searchResults.items.concat(data[category]) : data[category];

                setSearchResults({
                    hasMore: data.state.current_page < data.state.total_pages,
                    items: myItems,
                    totalPages: data.state.total_pages,
                    totalEntries: data.state.total_entries
                });
                setCurrentPage(Number(data.state.current_page));
            }
        }
    }

    const handleExerciseClick = (index) => {
        SwsAnalytics.event({
            category: 'Search Exercise',
            action: 'Click'
        });
        setCurrentItemIndex(index);
        setCurrentExercise(searchResults.items[index]);
        setShowExerciseModal(true);
    };

    const handleFeature = (event) => {
        event.preventDefault();

        const newIsFeatured = !searchResults.items[currentItemIndex].is_featured;
        const currentItem = searchResults.items[currentItemIndex];

        API.post(`${config.apiBase}/admin/exercises/${currentItem.id}/feature`,
            JSON.stringify({ featured: newIsFeatured }))
            .then(result => {
                let newSearchResults = Object.assign({}, searchResults);
                newSearchResults.items[currentItemIndex].is_featured = newIsFeatured;
                setSearchResults(newSearchResults);
            }
            )
            .catch(error => {
                alert(error.message);
            });
        return;
    };

    const handleLoggedOutAddWorkout = () => {
        setAfterLoginUrl(`${location.pathname}${location.search}`);
        setAlert({ autoClose: 3000, title: null, message: `Please register or login to modify a workout`, show: true });
        navigate('/register');
    };

    const handleMediaClick = (index) => {
        setCurrentItemIndex(index);
        setCurrentMedia(searchResults.items[index]);
        setShowExternalMediaModal(true);
    };

    const handleNew = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const path = `/${category}/new${location.search}`;

        if (!user?.id) {
            const resource = (category === 'external_media') ? 'Media Item' : Formatter.categoryToLabel(category);

            setAfterLoginUrl(path);
            setAlert({ autoClose: 1500, title: null, message: `Please register or login to create a new ${resource}`, show: true });
            navigate("/register");

        } else {
            navigate(path);
        }
    };

    const handleReload = () => {
        setCurrentPage(1);
        mutate(`${config.apiBase}${searchPath}?${searchQuery}&current_page=1`);
    }

    /* Disable searchbar expansion/contraction for now, see if it makes a difference */
    function handleSearchBoxFocus() {
        //setSearchBarInFocus(true);
    }

    // Only clear the q portion of the search if there is a query term
    // If there is not, clear all search terms
    const handleSearchIcon = () => {
        unFocusSearchBox();
        formRef.current.reset();

        if (location.search.length) {
            navigate(location.pathname);
        }
    };

    const handleSearchClick = (e) => {
        e.preventDefault()
        unFocusSearchBox()
        setCurrentPage(currentPage + 1);
    }

    const loadMoreItems = () => {
        if (currentPage < searchResults.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderList = () => {
        let scrollClass = 'list-scrollable pb-sm-3 list-group list-group-flush';

        if (searchResults.items.length == 0) return null;

        if (category === 'shares') {
            scrollClass = 'row';
        }

        const mapped_items = searchResults.items.map((item, i) => {
            if (category === 'exercises') {
                return (
                    <ExerciseListItem
                        currentGroupExercise={currentGroupExercise}
                        exercise={item}
                        key={`search-eli-${item.id}`}
                        isGiphyView={(category === 'exercises') && isGiphyView}
                        index={i}
                        handleExerciseClick={(index) => {
                            handleExerciseClick(index)
                        }}
                        setCurrentGroupExercise={setCurrentGroupExercise}
                        showAuthor={true}
                        showStars={true}
                        workout={workout}
                        {...props}
                    />
                );
            } else if (category === 'workouts') {
                return (
                    <WorkoutItem
                        item={item}
                        key={`workout-${item.id}`}
                        showStars={true}
                        {...props}
                    />
                );
            } else if (category === 'external_media') {
                return (
                    <Col xs={6} md={4} xl={3} className="p-0" key={`med-${i}`}>
                        <ExternalMediaItem
                            item={item}
                            handleMediaClick={(index) => {
                                handleMediaClick(index);
                            }}
                            key={`media-${item.id}`}
                            index={i}
                            {...props}
                        />
                    </Col>
                );

            } else if (category === 'shares') {
                return (
                    <WorkoutShareItem
                        item={item}
                        key={`share-${item.id}`}
                        index={i}
                        {...props}
                    />
                );
            }
            return (<></>);
        });

        let showGiphyView = false;

        if ((category === 'external_media') || ((category === 'exercises') && isGiphyView)) {
            showGiphyView = true;
            scrollClass += ' px-2';
        }

        if (!config) return null;

        const innerScrollClasses = showGiphyView ? 'row' : 'items';

        return (
            <>
                {(mapped_items.length === 0) && location.search &&
                    <div key='search-no-results' className="w-100 text-center">
                        No Results for search
                    </div>
                }
                {(mapped_items.length > 0) &&
                    <InfiniteScroll
                        className={scrollClass}
                        dataLength={mapped_items.length}
                        hasMore={searchResults.hasMore}
                        loader={<div className="w-100 text-center">Loading...</div>}
                        next={loadMoreItems}
                    >
                        <div className={innerScrollClasses}>
                            {mapped_items}
                        </div>
                    </InfiniteScroll>
                }
            </>
        );
    };

    const renderHeader = () => {
        let searchIcon = <FontAwesomeIcon icon={faSearch} />;

        if (location.search.length) {
            let queryParams = new URLSearchParams(location.search);

            if (queryParams.get('q') && queryParams.get('q').length) {
                searchIcon = <FontAwesomeIcon icon={faTimesCircle} />;
            }
        }

        let newButtonClasses = 'btn-sm';

        const searchBarCss = "d-flex w-100 list-header align-middle justify-content-between py-2 " +
            (searchBarInFocus ? "d-sm-row text-center" : "");

        const filterCss = searchBarInFocus ? "d-none d-md-block" : "";

        const giphyButtonClasses = searchBarInFocus ? "d-none d-md-flex align-items-center search-giphy" :
            "cursor-pointer d-flex align-items-center search-giphy";

        return (
            <div className={searchBarCss}>
                <div key="tl-search-form" className="search-bar-length">
                    <Form id="search-form" ref={formRef} onSubmit={handleSearchClick}>
                        <div className="input-group ps-2">
                            <FormControl
                                id="searchBox"
                                key="tl-form-control"
                                type="text"
                                className="search border-right-0 border"
                                minLength={2}
                                defaultValue={formQ}
                                onFocus={handleSearchBoxFocus}
                                onChange={handleChangeDebounced}
                                placeholder="search"
                            />
                            <button className="btn btn-outline-secondary" type="button" id="searchBoxAddon"
                                onClick={handleSearchIcon}>
                                {searchIcon}
                            </button>
                        </div>
                    </Form>
                </div>
                <div className="d-flex justify-content-around">
                    <div className={filterCss}>
                        <FiltersModal key="tl-filter-modal"
                            setShowFiltersModal={setShowFiltersModal}
                            showFiltersModal={showFiltersModal}
                            {...props} />

                    </div>
                    {(category === 'exercises') &&
                        <GiphyButton classes={giphyButtonClasses} clickHandler={toggleGiphyView}
                            isGiphyView={isGiphyView} />
                    }
                    <Link
                        to="#"
                        key="tl-new-link"
                        onClick={handleNew}
                        className="ctw-nav"
                    >
                        <Button variant="success" className={newButtonClasses}>
                            New
                        </Button>
                    </Link>
                </div>
            </div>
        );
    };

    const renderSubHeader = () => {
        let linkBlock = null;

        if (groupExerciseId && currentGroupExercise?.title?.length) {
            linkBlock = (
                <span className="align-middle current">
                    <Link to={`/exercises/${groupExerciseId}`}>
                        {currentGroupExercise.title}
                        <DeleteIcon onClick={handleClearGroupExerciseAddMode} />
                    </Link>
                </span>
            )
        } else if (currentWorkout?.title?.length &&
            ((currentWorkout.user_id === user?.id) || (user?.is_admin)) &&
            ['exercises', 'external_media'].includes(category)) {

            linkBlock = (
                <span className="align-middle current">
                    <Link to={`/workouts/${currentWorkout?.id}`}>
                        {currentWorkout.title}
                        <DeleteIcon onClick={handleClearCurrentWorkout} />
                    </Link>
                </span>
            )
        }

        if (!linkBlock) return null;

        return (
            <div className="list-sub-header">
                <span className="align-middle current">
                    Add to:
                </span>
                {linkBlock}
            </div>
        );
    };

    function toggleGiphyView() {
        if (isGiphyView === true) {
            setIsGiphyView(false)
        } else {
            setIsGiphyView(true)
            setCurrentPage(1);
        }
    }

    function unFocusSearchBox() {
        setSearchBarInFocus(false);
    }

    function emptySearchExplain() {
        let explain = `No ${category} for search `;
        if (searchTerm) {
            explain += `term: ${searchTerm}`;
        }
        const fsb = <FilterSelectionButton classes="py-0 px-1" clickHandler={() => setShowFiltersModal(true)} />;

        return (
            <div className="alert alert-secondary" role="alert">
                {explain}
                {isFilterSelected &&
                    <span>with filter {fsb}</span>
                }
            </div>
        );
    }

    return (
        <>
            <div className={listClass}>
                {renderHeader()}
                {((category === 'exercises') || (category === 'external_media')) && renderSubHeader()}
                <>
                    {isLoading && (searchResults.items.length == 0) &&
                        <div className="d-flex my-0 w-100 mt-2">
                            <HeartbeatImg />
                        </div>
                    }
                    {!isLoading && (searchResults.items.length == 0) && emptySearchExplain()}
                    {error &&
                        <div>
                            {error}
                        </div>
                    }
                </>
                {renderList()}
            </div>
            <ExerciseModal {...props}
                category={category}
                currentGroupExercise={currentGroupExercise}
                exercise={currentExercise}
                handleCloseModal={() => setShowExerciseModal(false)}
                handleFeature={handleFeature}
                handleLoggedOutAddWorkout={handleLoggedOutAddWorkout}
                showExerciseModal={showExerciseModal}
                workout={workout}
                workoutPerform={currentWorkout ? currentWorkout.perform : {}}
            />
            <ExternalMediaModal {...props}
                handleCloseModal={() => setShowExternalMediaModal(false)}
                handleLoggedOutAddWorkout={handleLoggedOutAddWorkout}
                media={currentMedia}
                readOnly={true}
                reload={handleReload}
                showExternalMediaModal={showExternalMediaModal}
                workout={workout}
            />
        </>
    )
}