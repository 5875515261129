import { Button } from "react-bootstrap";
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../../providers/search_provider";

const FilterSelectionButton = (props) => {
    const { classes, clickHandler } = props;
    const { isFilterSelected } = useContext(SearchContext);

    const filter = <FontAwesomeIcon icon={faSlidersH} />;
    const allClasses = `btn-sm ${classes}`;

    return (
        <Button
            variant={isFilterSelected ? 'dark' : 'light'}
            onClick={clickHandler}
            className={allClasses}>
            {filter}
        </Button>
    )
};

export default FilterSelectionButton;
