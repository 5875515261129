import React from "react";

export default function ReferenceLink(props) {
    const { item, showHr } = props;

    if (!item?.reference_link) return null;

    return (
        <div className="py-2">
            <div className="reference w-100 text-center">
                <a href={item.reference_link} rel="noreferrer"
                    target="_blank">{item.reference_text ? item.reference_text : "Reference"}</a>
            </div>
            {showHr && <hr />}
        </div>
    );
}