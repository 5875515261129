import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import API from "../../utils/api";
import { ConfigContext } from "../../utils/config_context";
import { NavContext } from "../../providers/nav_provider";

export default function NavEditButtons(props) {
    const {
        handleSave, isDisabled, isSaving, mode,
        reload, resourceId, resourceName, resourceUrlStub, showButtons 
    } = props;

    const { dirty, setAlert } = useContext(NavContext);
    const config = useContext(ConfigContext);
    const urlStub = resourceUrlStub || `${resourceName}s`;

    let navigate = useNavigate();

    const handleAlertConfirm = () => {
        navigate('..', { relative: 'path' });
        reload();
    }

    const handleCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (dirty) {
            setAlert({
                title: null,
                message: 'Unsaved changes will be lost',
                show: true,
                confirmFn: () => handleAlertConfirm,
                confirmLabel: 'Yes',
                cancelLabel: 'No'
            });
        } else {
            navigate(-1);
        }
    };

    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAlert({
            title: null,
            message: `Are you sure you want to delete this ${resourceName}?`,
            confirmFn: () => handleDeleteConfirm,
            show: true
        });
    };

    const handleDeleteConfirm = () => {
        API.del(`${config.apiBase}/${urlStub}/${resourceId}`)
            .then(result => {
                navigate(`/search/${urlStub}`)
            }
            )
            .catch(error => {
                alert(error.message)
            });
        return;
    };

    const renderCancelButton = () => {
        return (
            <div className="nav-link px-1" key="neb-cancel-btn">
                <Button variant="secondary" className="btn btn-sm"
                    onClick={handleCancel} disabled={isDisabled}>Cancel</Button>
            </div>
        )
    };

    const renderDeleteButton = () => {
        return (
            <div className="nav-link px-1" key="neb-del-btn">
                <Button variant="danger" className="btn btn-sm"
                    onClick={handleDelete} disabled={isDisabled}>Delete</Button>
            </div>
        )
    }

    const renderSaveButton = () => {
        return (
            <div className="nav-link px-1" key="neb-save-btn">
                <Button variant="primary" className="btn btn-sm" disabled={isDisabled}
                    onClick={handleSave}>Save</Button>
            </div>
        )
    }

    const renderButtons = () => {
        if (mode === 'new') {
            return (<>
                {renderCancelButton()}
                {renderSaveButton()}
            </>);
        } else if (mode === 'show') {
            return ( <>{typeof showButtons === 'function' && showButtons()}</> );
        } else if (mode === 'edit') {
            return (<>
                {renderCancelButton()}
                {renderDeleteButton()}
                {renderSaveButton()}
            </>)
        }
    }

    // New Mode: Cancel     Save
    // Show Mode: <  {children} Edit
    //       Exercise Set: children = Add
    //       Exercise Single/YT: children = Create Superset
    // Edit mode: Cancel Delete Save
    // 

    return (
        <Form id="nav-edit-buttons-form" className="d-flex w-100 justify-content-between" inline='true'>
            {isSaving &&
                <div className="nav-link px-2 text-light">
                    Saving...
                </div>
            }
            {!isSaving && renderButtons()}
        </Form>
    );
}
