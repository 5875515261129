import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCopy, faStar } from "@fortawesome/free-solid-svg-icons";
import EditButton from "../common/edit_button";
import AddButton from "../common/add_button";

export default function ShowNavButtons(props) {
    const { handleAddButton, handleCopyButton, handleFeature, handleStartButton,
        isDisabled, showAddButton, showEditButton, showFeatureButton,
        setIsPerform, subject } = props;

    const copyIcon = <FontAwesomeIcon icon={faCopy} />;
    const star = <FontAwesomeIcon icon={faStar} />;
    const chevron = <span className='pe-1'><FontAwesomeIcon icon={faChevronLeft} /></span>;

    let location = useLocation();
    let navigate = useNavigate();

    const handleEditButton = () => {
        navigate(`${location.pathname}/edit?${location.search}`);
    };

    const renderAddButton = () => {
        return (
            <Nav.Link href="#">
                <AddButton clickHandler={handleAddButton} isDisabled={isDisabled}/>
            </Nav.Link>
        )
    }

    const renderBackButton = () => {
        return (
            <div className="nav-link px-1" key="neb-back-btn">
                <Button variant='primary' className="btn btn-sm" disabled={isDisabled}
                    onClick={() => navigate(-1)}>{chevron}</Button>
            </div>
        )
    }

    const renderCopyButton = () => {
        return (
            <Nav.Link href="#">
                <Button variant="info" className="btn btn-sm"
                    onClick={handleCopyButton}>{copyIcon} Copy</Button>
            </Nav.Link>
        )
    }

    const renderEditButton = () => {
        return (
            <div className="nav-link px-1" key="neb-edit-btn">
                <EditButton clickHandler={handleEditButton} disabled={isDisabled} />
            </div>
        )
    }

    const renderFeatureButton = () => {
        return (
            <div className="nav-link px-1" key="neb-wrkout-feature-btn">
                <Button variant={subject?.is_featured ? 'warning' : 'secondary'} className="btn btn-sm"
                    disabled={isDisabled} onClick={handleFeature}>{star}</Button>
            </div>
        );
    }

    const renderStartButton = () => {
        return (
            <Nav.Link href="#">
                <Button variant="success" className="btn btn-sm"
                    onClick={() => setIsPerform(true)}>Start</Button>
            </Nav.Link>
        )
    }

    return (
        <>
            {renderBackButton()}
            {showFeatureButton && renderFeatureButton()}
            {typeof handleCopyButton === 'function' && renderCopyButton()}
            {showAddButton && renderAddButton()}
            {typeof handleStartButton === 'function' && renderStartButton()}
            {showEditButton && renderEditButton()}
        </>
    );
};
