let ObjectDefaults = {
    exercise() {
        return {
            id: null,
            is_group: false,
            is_public: true,
            title: "",
            tags: [],
            tag_ids: [],
            standard_animation: null,
            preferred_video: null,
            exerciseMediumId: null,
            exerciseMediumType: null,
            referenceLink: null,
            version: 1
        }
    },

    externalMedia() {
        return {
            id: null,
            link: null,
            title: null,
            alias: null,
            username: null,
            audio_only: false,
            og_data: {}
        }
    },

    image() {
        return {
            aasm_state: 'empty',
            id: null,
            image: null,
            src: null,
            tempUploadFile: null,
            thumb: null,
            url: null
        }
    },

    // ToDo: consolidate this

    image2() {
        return {
            aasm_state: 'empty',
            id: null,
            image: null,
            src: null,
            thumb: null,
            url: null
        }
    },

    profile() {
        return {
            username: null,
            slug: null,
            image: null,
            description: null,
            link: null,
            thumb: null,
            src: null,
            numExercises: 0,
            numWorkouts: 0,
            numShares: 0,
            lastExercises: [],
            lastWorkouts: [],
            shares: []
        }
    },

    system() {
        return {
            "version": "609fd586",
            "featured_workout": {
                "id": 1,
                "is_featured": true,
                "is_public": true,
                "title": "Old Workout",
                "total_secs": 40,
                "thumb": "",
                "user_id": 11,
                "author": "tiny_cassin",
                "username": "tiny_cassin",
                "exercise_ids": [
                    1,
                    2,
                    5,
                    7,
                    8,
                    9,
                    10,
                    11
                ],
                "reference_link": "",
                "reference_text": null,
                "precursor_link": null,
                "warmup_link": null,
                "main_link": null,
                "synced": null,
                "timed": true,
                "cooldown_link": null,
                "description": "",
                "preview_image": "uploads/11/wp_1/workout-preview-1.png",
                "preview_video": null,
                "created": 1621087628,
                "updated": 1624488382,
                "version": "60d3b9be",
                "animeLoading": false,
                "animeLoaded": false,
                "perform": {
                    "sets": 1,
                    "timed": true,
                    "showTitle": true,
                    "playSounds": false,
                    "playMidpoint": false,
                    "exercises": [
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    "round": 5,
                    "rest": 0
                },
                "exercise_videos": [
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e24da04cc98b5b4946918a03906c7bb8e96c0d/altLegAndArmsUp.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e36d9985f6102a1c82defe31406968bd02d644fa/behindHeadLunges.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--81ea319fd3981747e6bcf5c6667542e80bd333bb/pausedPushUps.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5713de7393126c03da75a739641b48104d751f10/plankCrossLegUps.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--eb69ac33ff09a7a0fa24b7dce44230ca672f8b42/plankLegUps.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2872377b8b8d13ae14b55067b3b9b234eb0c9e6a/pushUpHeils.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a65748c5e1676c0844ff408cfac6819ca9a7351/pushUpHop.mp4?disposition=attachment",
                    "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--556966f29960b53d04918fb563f1e923f91fda22/pushupAlternateArms.mp4?disposition=attachment"
                ],
                "exercise_titles": [
                    "altLegAndArmsUp",
                    "behindHeadLunges",
                    "pausedPushUps",
                    "plankCrossLegUps",
                    "plankLegUps",
                    "pushUpHeils",
                    "pushUpHop",
                    "pushupAlternateArms"
                ],
                "workout_image": null
            },
            "tags": [
                {
                    "id": 1,
                    "title": "upper body",
                    "description": null
                },
                {
                    "id": 2,
                    "title": "lower body",
                    "description": null
                },
                {
                    "id": 3,
                    "title": "core",
                    "description": null
                },
                {
                    "id": 4,
                    "title": "cardio",
                    "description": null
                },
                {
                    "id": 5,
                    "title": "legs",
                    "description": null
                },
                {
                    "id": 6,
                    "title": "glutes",
                    "description": null
                },
                {
                    "id": 7,
                    "title": "chest",
                    "description": null
                },
                {
                    "id": 8,
                    "title": "back",
                    "description": null
                },
                {
                    "id": 9,
                    "title": "arms",
                    "description": null
                },
                {
                    "id": 10,
                    "title": "full body",
                    "description": null
                },
                {
                    "id": 11,
                    "title": "balance",
                    "description": null
                }
            ],
            "featured_workout_shares": [],
            "featured_exercises": []
        }
    },

    workout() {
        return {
            id: null,
            is_featured: false,
            username: null,
            description: null,
            exercises: [],
            title: "",
            version: 1,
            prompt: null,
            precursor_link: null,
            warmup_link: null,
            main_link: null,
            synced: false,
            cooldown_link: null,
            total_secs: null
        }
    },

    workoutPerform() {
        return {};
    }

};

export default ObjectDefaults;