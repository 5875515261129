import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ExerciseCard from './exercises/exercise_card';
import ReactPlayer from "react-player";
import { ConfigContext } from '../utils/config_context';
import ExerciseImageComponent from './exercises/exercise_image_component';

export default function Home(props) {
    const { system } = props;

    const config = useContext(ConfigContext);

    const [exerciseVideoIndex, setExerciseVideoIndex] = useState(0);
    const [workoutState, setWorkoutState] = useState('init'); // init, play

    const navigate = useNavigate();

    const handleInfoShare = (event) => {
        window.location.href = '/sharing';
    };

    const playerOnEnded = () => {
        const videoPlaylistLength = system.featured_workout?.exercise_media?.length;

        const newExerciseVideoIndex = (exerciseVideoIndex + 1 < videoPlaylistLength) ? exerciseVideoIndex + 1 : 0;

        setExerciseVideoIndex(newExerciseVideoIndex);
    };

    const handleImageClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate(`/exercises/${system.featured_exercises[0].id}`);
    }

    const renderImageComponent = (exercise, index) => {
        return (
            <ExerciseImageComponent
                {...props}
                exercise={exercise}
                handleImageClick={handleImageClick}
                index={index}
                isGiphyView={true}
            />
        );
    }

    const renderFeaturedExercises = () => {
        const featured_exercises = system.featured_exercises;

        return (
            <>
                <Row>
                    <div className="mx-auto ms-2">
                        <h6 className="font-weight-bold">
                            Featured Exercises:
                        </h6>
                    </div>
                </Row>
                <Row>
                    {featured_exercises.map((exercise, index) => {
                        const myImageComponent = renderImageComponent(exercise, index);

                        return (
                            <Col key={`feat-${index}`} xs={6} sm={4} className="p-0">
                                <Link to={`/exercises/${exercise.id}`}>
                                    <ExerciseCard {...props}
                                        imageComponent={myImageComponent}
                                        animate={true}
                                        index={index}
                                        exercise={exercise} />
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </>
        );
    };

    const renderHelpLinks = () => {
        return (
            <div className="w-100 pt-2 text-center">
                <h6>
                    <a href={config?.userManualLink} target="_other" rel="noreferrer">
                        User Manual
                    </a>
                </h6>
            </div>
        );
    };

    const renderFeaturedWorkout = () => {
        let workout = system?.featured_workout;
        let currentWorkoutVideo = null;
        let currentExerciseTitle = null;
        let workoutImagePath = false;

        if (!workout) return null;

        if ((workoutState === 'init') && (workout && workout.workout_image && workout.workout_image.src)) {
            workoutImagePath = `${config?.s3Basepath}/${workout.workout_image.src}`;
        } else {
            currentWorkoutVideo = workout?.exercise_media?.[exerciseVideoIndex];
            currentExerciseTitle = workout?.exercise_titles?.[exerciseVideoIndex];
        }

        return (
            <>
                {(workoutImagePath || currentWorkoutVideo) &&
                    <Row>
                        <div className="mx-auto ms-2">
                            <h6 className="font-weight-bold">
                                Featured Workout:
                            </h6>
                        </div>
                    </Row>
                }
                {workoutImagePath && workout && workout.workout_image &&
                    <div className="card bg-dark border-light">
                        <Link to={`/workouts/${workout.id}`}>
                            <div className="d-flex justify-content-center bg-dark">
                                <img className="image-preview" alt="image_preview" src={workoutImagePath} />
                            </div>
                            <div className="card-img-overlay pt-0 exercise-card">
                                <div className="text-center">
                                    <div className="text-white text-shadow">
                                        {system.featured_workout.title}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                }
                {currentWorkoutVideo &&
                    <Row>
                        <Col xs={12}>
                            <div className="card bg-dark">
                                <ReactPlayer
                                    url={currentWorkoutVideo}
                                    controls={true}
                                    onEnded={playerOnEnded}
                                    className='react-player img-responsive w-100 card-img'
                                    playing
                                    volume={0}
                                    width="100%"
                                    height="100%"
                                />
                                {currentExerciseTitle &&
                                    <div className="card-img-overlay pt-0 exercise-card bg-dark pt-1"
                                        style={{ zIndex: '-1' }}>
                                        <div className="text-center">
                                            <div className="card-title text-white text-shadow">
                                                {currentExerciseTitle}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                }
            </>
        );
    };

    const featured_shares = system?.featured_workout_shares;

    return (
        <div className='top-component'>
            <Row>
                <div className="mx-auto ms-2">
                    <h3>Welcome to {`${config?.sitename}`}</h3>
                </div>
            </Row>
            <Row>
                <div className="mx-auto ms-2">
                    A Workout PERFORMANCE site.
                </div>
            </Row>
            {renderFeaturedWorkout()}
            {renderHelpLinks()}
            {system?.featured_exercises && (system.featured_exercises.length > 0) && renderFeaturedExercises()}
            <br />
            {featured_shares && (featured_shares.length > 0) &&
                <>
                    <Row>
                        <div className="mx-auto">
                            <h6>
                                YouTube Posts:
                                <Link to="#" onClick={(event) => {
                                    handleInfoShare(event)
                                }}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Link>
                            </h6>
                        </div>
                    </Row>
                    <br />
                </>
            }
            <br />
        </div>
    );
}