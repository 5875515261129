import React from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import ConfigTimedWorkoutControls from "./config_timed_workout_controls";
import ExerciseList from "../exercises/exercise_list";
import TagWrapper from "../common/tag_wrapper";
import Formatter from "../../utils/formatter";

export default function ConfigWorkoutPerformModal(props) {
    const {
        exercises,
        handleDone,
        layout,
        mode,
        performState,
        playSounds,
        renderFooter,
        shareDescription,
        setLayout,
        setSizeRatio,
        sizeRatio,
        shareTitle,
        show,
        showExercises,
        timed,
        title,
        updateConfigWorkoutModalState,
        workout,
        workoutPerform
    } = props;

    function handleDescriptionChange(event) {
        updateConfigWorkoutModalState({ description: event.target.value, dirty: true });
    }

    function handleTimedChange() {
        updateConfigWorkoutModalState({ timed: !timed });
    }

    function handleTitleChange(event) {
        updateConfigWorkoutModalState({ shareTitle: event.target.value, dirty: true });
    }

    function handleTogglePlaySounds() {
        updateConfigWorkoutModalState({ playSounds: !playSounds });
    }

    function renderBellSwitch() {
        return (
            <>
                <Row>
                    <Form id="config-workout-form-bell">
                        <Form.Check isValid={true} type="switch" id="bell-switch">
                            <Form.Check.Label><h5 className="pt-1">Play Bells</h5></Form.Check.Label>
                            <Form.Check.Input type="checkbox" checked={playSounds} onChange={handleTogglePlaySounds} />
                        </Form.Check>
                    </Form>
                </Row>
            </>
        );
    }

    function renderLayoutOptions() {
        return (
            <>
                <Row>
                    <h5>Layout</h5>
                </Row>
                <Row>
                    <TagWrapper key='auto-layout' tagName='auto' selected={layout === 'auto'}
                        clickHandler={() => {
                            setLayout('auto')
                        }}>
                        {Formatter.tagToLabel('Auto')}
                    </TagWrapper>
                    <TagWrapper key='corner-layout' tagName='corner' selected={layout === 'corner'}
                        clickHandler={() => {
                            setLayout('corner');
                        }}>
                        {Formatter.tagToLabel('Corner')}
                    </TagWrapper>
                </Row>
            </>
        );
    }

    function renderSizeRatio() {
        return (
            <>
                <Row className="pt-2">
                    <h5>Size Ratio</h5>
                </Row>
                <Row>
                    <TagWrapper key='small-media' tagName='small-media' selected={sizeRatio === 'small-media'}
                        clickHandler={() => {
                            setSizeRatio('small-media')
                        }}>
                        {Formatter.tagToLabel('Small Media')}
                    </TagWrapper>
                    <TagWrapper key='equal' tagName='equal' selected={sizeRatio === 'equal'}
                        clickHandler={() => {
                            setSizeRatio('equal');
                        }}>
                        {Formatter.tagToLabel('Equal')}
                    </TagWrapper>
                    <TagWrapper key='equal' tagName='small-exercise' selected={sizeRatio === 'small-exercise'}
                        clickHandler={() => {
                            setSizeRatio('small-exercise');
                        }}>
                        {Formatter.tagToLabel('Small Exercise')}
                    </TagWrapper>
                </Row>
            </>

        )
    }

    function renderShareOptions() {
        return (
            <>
                <Form.Group>
                    <Row>
                        <Col xs={3}>
                            <Form.Label>Title</Form.Label>
                        </Col>
                        <Col xs={9}>
                            <Form.Control required type="text" name="title" placeholder="Title"
                                value={shareTitle || ''}
                                onChange={(event) => {
                                    handleTitleChange(event)
                                          }}/>
                            <Form.Control.Feedback type="invalid">
                                Please set a workout share title
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col xs={12}>
                            <Form.Control type="text" as="textarea" rows="3" name="description"
                                          placeholder="Description"
                                          value={shareDescription || ''}
                                          onChange={(event) => {
                                              handleDescriptionChange(event)
                                          }}/>
                        </Col>
                    </Row>
                </Form.Group>
            </>
        )
    }

    function renderPerformBody() {
        const listMode = workout.synced ? 'link' : 'detailed';

        return (
            <>
                {workout?.workout_type === "timed" &&
                    <ConfigTimedWorkoutControls  {...props} />
                }
                {showExercises &&
                    <ExerciseList {...props}
                        classes="pt-2"
                        isGiphyView={false}
                        listMode={listMode}
                        workoutPerform={workoutPerform} />
                }
            </>
        );
    }

    const renderError = !exercises || (exercises.length === 0);

    if (!workoutPerform) return null;

    return (
        <Form id="config-workout-form">
            <Modal id="configure-workout-modal" animation={false} show={show} onHide={handleDone}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleDone} />
                </Modal.Header>
                <Modal.Body>
                    {renderError &&
                        <>
                            <Row>
                                <Col xs={12} className="text-center">
                                    This workout cannot be performed.
                                </Col>
                            </Row>
                            <div style={{ padding: '1rem' }}></div>
                        </>
                    }
                    {renderBellSwitch()}
                    {renderLayoutOptions()}
                    {renderSizeRatio()}
                    {performState !== 'done' && mode === 'perform' && renderPerformBody()}
                    {mode === 'share' && renderShareOptions()}
                </Modal.Body>
                {(typeof renderFooter === 'function') && renderFooter()}
            </Modal>
        </Form>
    );
}