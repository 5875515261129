import React, { useContext, useEffect, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    RouterProvider,
    Route
} from 'react-router-dom';
import * as Sentry from "@sentry/browser";
import WorkoutWrapper from './components/workouts/workout_wrapper';
import Home from './components/home';
import LoginPage from './components/users/login_page';
import RegisterPage from './components/users/register_page';
import PasswordReset from './components/users/password_reset';
import 'bootstrap/dist/js/bootstrap';
import "./App.scss";
import TopNavbar from "./components/nav/top_navbar";
import Search from "./components/search";
import ProfilePage from './components/users/profile_page';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SearchProvider from './providers/search_provider';
import NavProvider, { NavContext } from "./providers/nav_provider";
import UserProvider, { UserContext } from './providers/user_provider';
import { ConfigContext } from "./utils/config_context";
import ApiLoadingWrapper from "./components/common/api_loading_wrapper";
import ClipPlayTest from "./components/players/clip_play_test";
import useSWRImmutable from "swr";
import { SwrUtils } from './utils/swr_utils';
import Root from "./root";
import Exercise from "./components/exercises/exercise";
import AlertModal from "./components/modals/alert_modal";
import ExternalMedia from "./components/external_media/external_media";
import { ErrorBoundary } from 'react-error-boundary';
import PlayerTest from './components/admin/player_test';

const TopErrorFallbackRender = ({ error, resetErrorBoundary }) => {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

export default function App(props) {
    const systemFetcher = (...args) => fetch(...args).then((res) => {
        return res.json()
    });

    const config = useContext(ConfigContext);
    const { mutate, resetUser, user, userIsLoading } = useContext(UserContext);

    const [afterLoginUrl, setAfterLoginUrl] = useState(null);
    // none, workout, groupExercise
    const [system, setSystem] = useState(props.system);
    const [userWorkouts, setUserWorkouts] = useState([]);

    const { setAlert } = useContext(NavContext);

    const { error, isLoading } = useSWRImmutable(config.apiBase ? `${config.apiBase}/workout_system` : null,
        systemFetcher, {
        ...SwrUtils.stdOptions,
        onSuccess: (data) => setSystem(data)
    });

    useEffect(() => {
        //if (window.referrer.match(/^https:\/\/youtube.com/)) {
        if (window?.referrer?.match(/^https:\/\/staging.fastfitgx.com/)) {
            setAlert({ title: 'YouTube landing', message: 'YouTube landing modal', show: true });
        }
    }, [setAlert]);

    const handleResetError = (details) => {
        resetUser();
        setAfterLoginUrl(null);
        setSystem(props.system);
        setUserWorkouts([]);

        if (config.apiBase && user?.id) {
            mutate(`${config.apiBase}/users/${user.id}`);
        }
    };

    const logError = (error, info) => {
        console.error(error, info);
        Sentry.captureEvent(error, info);
    };

    /*
    const renderWorkoutShareItem = (item, i) => {
        return (
            <Link key={`item-${i}`} to={`${this.props.itemType}/${item.id}`}
                  className="list-group-item flex-column align-items-start addable">
                <div className="d-flex w-100 justify-content-between">
                    <div>
                            <span className="align-middle">
                                title: {item.title}
                            </span>
                    </div>
                </div>
            </Link>
        );
    };
     */

    const commonProps = {
        afterLoginUrl: afterLoginUrl,
        setAfterLoginUrl: setAfterLoginUrl,
        setUserWorkouts: setUserWorkouts,
        system: system,
        userWorkouts: userWorkouts,
    };

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Root {...commonProps} />}>
                <Route path="/" element={<TopNavbar {...commonProps} />}>
                    <Route index element={
                        <Home category='home'
                            {...commonProps}
                            mode='index'
                        />
                    } />
                    <Route path="login" element={
                        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
                            <LoginPage
                                {...commonProps}
                            />
                        </GoogleReCaptchaProvider>
                    } />
                    <Route path="register" element={
                        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
                            <RegisterPage
                                {...commonProps}
                            />
                        </GoogleReCaptchaProvider>
                    } />
                    <Route path="password_reset" element={
                        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
                            <PasswordReset
                                {...commonProps}
                            />
                        </GoogleReCaptchaProvider>
                    } />
                    <Route path="admin">
                        <Route index element={
                            <Navigate to='/admin/player' replace />
                        } />
                        <Route path="player" element={
                            <PlayerTest {...commonProps} />
                        } />
                    </Route>
                    <Route path="search">
                        <Route index element={
                            <Navigate to='/search/exercises' replace />
                        } />
                        <Route path=":categoryParam">
                            <Route path="exercises/:groupExerciseId/add" element={
                                <SearchProvider categoryProp="exercises">
                                    <Search {...commonProps} categoryProp='exercises' mode='index' />
                                </SearchProvider>
                            } />
                            <Route path="workouts/:workoutId/add" element={
                                <SearchProvider categoryProp="workouts">
                                    <Search {...commonProps} categoryProp='workouts' mode='index' />
                                </SearchProvider>
                            } />
                            <Route index element={
                                <SearchProvider>
                                    <Search {...commonProps}
                                        mode='index' />
                                </SearchProvider>
                            } />
                        </Route>
                    </Route>
                    <Route path="exercises">
                        <Route index element={
                            <Navigate to='/search/exercises' replace />
                        } />
                        <Route path="new" element={
                            <Exercise mode='new' {...commonProps} />
                        } />
                        <Route path=":exerciseId/edit" element={
                            <Exercise mode='edit' {...commonProps} />
                        } />
                        <Route path=":exerciseId" element={
                            <Exercise mode='show' {...commonProps} />
                        } />
                    </Route>
                    <Route path="users">
                        <Route index
                            element={<div style={{ paddingTop: '100px' }}>Hello Users</div>}
                        />
                        <Route path=":userSlug"
                            element={<ProfilePage {...commonProps} mode="profile" />}
                        />
                    </Route>
                    <Route path="external_media">
                        <Route index element={
                            <Navigate to="/search/external_media" replace />
                        } />
                        <Route path="new" element={
                            <ExternalMedia categoryProp='external_media'
                                mode='new' {...commonProps} />
                        } />
                        <Route path=":externalMediaId/edit" element={
                            <ExternalMedia mode='edit' {...commonProps} />
                        } />
                        <Route path=":externalMediaId" element={
                            <ExternalMedia mode='show' {...commonProps} />
                        } />
                    </Route>
                    <Route path="workouts">
                        <Route index element={
                            <Navigate to="/search/workouts" replace />
                        } />
                        <Route path="new" element={
                            <WorkoutWrapper categoryProp='workouts'
                                mode='new'
                                {...commonProps} />
                        } />
                        <Route path=":workoutId/edit" element={
                            <WorkoutWrapper categoryProp='workouts'
                                mode="edit"
                                {...commonProps}
                            />
                        } />
                        <Route path=":workoutId" element={
                            <WorkoutWrapper categoryProp='workouts'
                                mode='show'
                                {...commonProps}
                            />
                        } />
                    </Route>
                    <Route path="workouts/:workoutId/perform" element={
                        <Navigate to={`/workouts/:workoutId`} replace />
                    } />
                    <Route path="clipplaytest" element={
                        <ClipPlayTest />
                    } />
                </Route>
            </Route >
        )
    );

    if (isLoading || userIsLoading) {
        return (
            <ApiLoadingWrapper />
        )
    }

    if (error) {
        return "FastfitGx appears to be offline. Retrying...";
    }

    return (
        <ErrorBoundary FallbackComponent={TopErrorFallbackRender} onError={logError} onReset={handleResetError}>
            <NavProvider>
                <UserProvider>
                    <RouterProvider router={router} />
                    <AlertModal />
                </UserProvider>
            </NavProvider>
        </ErrorBoundary>
    );
}

/*
const AppRoutes = (props) => {
    const config = useContext(ConfigContext);

    return (
        <Router>
            <Routes>
                <Route path="/clipplaytest" element={
                    <ClipPlayTest/>
                }/>
                <Route path="/" element={
                    <>
                        <NavWrapper {...props} />
                        <Home category='home'
                              {...props}
                              mode='index'
                        />
                    </>
                }/>
            </Routes>
        </Router>
    );
}


/*

    <Route path="/shares">
        <TopList category='shares'
                 {...this.props}
                 {...commonProps}
                 headerLabel="Shares"
                 showNew={false}
                 mode='index'
        />
    </Route>
    <Route path="/shares/:share_id">
        <WorkoutShareWrapper mode='show'
                             {...this.props}
                             {...commonProps}
        />
    </Route>
    <Route path="/shares/:share_id/perform">
        <WorkoutShareWrapper mode='perform'
                             {...this.props}
                             {...commonProps}
        />
    </Route>
    <Route path="/workouts/:workoutId/shares">
        <NavWrapper category='shares'
                    {...this.props}
                    {...commonProps}>
            <TopList default
                     category='shares'
                     showWorkoutLink={true}
                     {...this.props}
                     {...commonProps}/>
        </NavWrapper>

        */
