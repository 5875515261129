import React from 'react';
import ExerciseAnimatedImage from './exercise_animated_image';
import ExerciseClipMedia from './exercise_clip_media';
import ExerciseGroupImageCarousel from './exercise_group_image_carousel';


export default function ExerciseImageComponent(props) {
    const { exercise, handleImageClick, isGiphyView } = props;

    if (!exercise) return null;

    if (exercise.clip) {
        return (
            <ExerciseClipMedia
                {...props}
                mode="show"
                clipMode="image"
                classes="w-100 img-responsive card-img"
                playerShouldBePlaying={false}
            />
        );

    } else if (exercise.is_group) {
        return (
            <ExerciseGroupImageCarousel {...props} />
        );
    } else {
        return (
            <ExerciseAnimatedImage animate={isGiphyView}
                classes='card-img'
                exercise={exercise}
                onClick={handleImageClick}
                size="sm" />
        )
    }

};