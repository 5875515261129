import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { SwsAnalytics } from "../../services/sws_analytics";
import _ from 'lodash';
import { Row, Button, Modal, Form } from 'react-bootstrap';

export default function TagsWithModal(props) {
    const { exercise, mode, setTagIds, system, tagIds } = props;

    const [localTagIds, setLocalTagIds] = useState([]);

    const tags = system.tags.filter(tag => exercise?.tag_ids?.includes(tag.id));
    const [showTagsModal, setShowTagsModal] = useState(false);
    const showTagsLabel = tagIds?.length > 0 || mode === 'new' || mode === 'edit';

    const tagClassNames = 'btn tag-name tag-select selected';

    useEffect(() => {
        setLocalTagIds(tagIds);

    }, [tagIds]);

    const handleCancel = () => {
        setShowTagsModal(false);
    }

    const handleSet = () => {
        SwsAnalytics.event({
            category: 'Exercise',
            action: 'Set Tags'
        });

        setTagIds(localTagIds);
        setShowTagsModal(false);
    }

    const handlePageTagsClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (['edit', 'new'].includes(mode)) {
            setShowTagsModal(true);
        }
    };

    const toggleTag = (tagId) => {
        let newTagIds;

        if (_.indexOf(localTagIds, tagId) > -1) {
            newTagIds = localTagIds.filter(tag_id => tag_id !== tagId);
        } else {
            newTagIds = [...localTagIds];
            newTagIds.unshift(tagId);
        }
        setLocalTagIds(newTagIds);
    };

    const renderTag = (tag) => {
        let variantName = "sm";
        let classes = "tag-name";

        if (_.indexOf(localTagIds, tag.id) > -1) {
            classes += " tag-select selected";
        } else {
            classes += " btn-secondary";
        }

        return (
            <Button key={`tag-${tag.id}`} variant={variantName} className={classes} onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                toggleTag(tag.id)
            }}>{tag.title}</Button>
        );
    };

    const renderTagsSection = () => {
        return (
            <div>
                <Row>
                    <Form.Group controlId="tags-teaser">
                        {showTagsLabel &&
                            <Col xs={2}>
                                <Form.Label>
                                    <h5>Tags
                                        {tags.length === 0 &&
                                            <span style={{ color: 'blue', fontSize: '1rem' }} className="ps-2" onClick={(event) => handlePageTagsClick(event)}>
                                                None
                                            </span>
                                        }
                                    </h5>
                                </Form.Label>
                            </Col>
                        }
                    </Form.Group>
                </Row>
                {(tags.length > 0) &&
                    <Row>
                        <Col xs={12} className="d-flex flex-wrap">
                            {tags.map((tag) =>
                            (
                                <Button key={`tag-${tag.id}`}
                                    onClick={(event) => handlePageTagsClick(event)}
                                    variant="sm"
                                    className={tagClassNames}>
                                    {tag.title}
                                </Button>
                            ))}
                        </Col>
                    </Row>
                }
            </div>
        )
    };

    const renderTagsModal = () => {
        return (
            <Modal id="tags-modal" animation={false} show={showTagsModal}>
                <Modal.Header>
                    <Modal.Title>Tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap">
                        {system.tags.map((tag) =>
                            renderTag(tag)
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-between">
                        <Button variant="secondary" onClick={() => {
                            handleCancel()
                        }}>Cancel</Button>
                        <Button variant="primary" onClick={() => {
                            handleSet()
                        }}>Set</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            {showTagsModal && renderTagsModal()}
            {renderTagsSection()}
        </>
    )
}