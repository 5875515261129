import React, {useContext, useState} from "react";
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faCamera} from "@fortawesome/free-solid-svg-icons";
import ImageUploaderModal from "../modals/image_uploader_modal";
import {ConfigContext} from "../../utils/config_context";

const ImageUploader = (props) => {
    const {image, isEditable, thumb} = props;

    const userIcon = <span style={{fontSize: '4rem'}}><FontAwesomeIcon icon={faUser} className="pe-1"/></span>;
    const cameraIcon = <FontAwesomeIcon icon={faCamera} className="px-1"/>;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showExpandedImage, setShowExpandedImage] = useState(false);
    const config = useContext(ConfigContext);

    const imageSrc = image ? `${config.s3Basepath}/${image}` : null;
    const thumbSrc = thumb ? `${config.s3Basepath}/${thumb}` : null;

    const handleModalCloseEvent = () => {
        setModalIsOpen(false);
    }

    return (
        <>
            <div className="card bg-dark text-white">
                {thumb &&
                <img alt="uploader-thumb"
                     className="img-fluid w-100 card-img"
                     src={thumbSrc}
                     onClick={() => setShowExpandedImage(true) }/>
                }
                {!thumb &&
                <div className="img-fluid w-100 card-img d-flex justify-content-center">
                    {userIcon}
                </div>
                }
                {isEditable &&
                <div className="card-icon-bottom-right" style={{background: 'black'}} onClick={() => setModalIsOpen(true)}>
                    {cameraIcon}
                </div>
                }
            </div>
            {modalIsOpen && <ImageUploaderModal handleModalClose={handleModalCloseEvent} {...props} /> }
            <Modal show={showExpandedImage && imageSrc} onClick={() => setShowExpandedImage(false)} onHide={() => setShowExpandedImage(false)}>
                <Modal.Body>
                    <img className="w-100 img-fluid" alt="profile" src={imageSrc}/>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ImageUploader;
