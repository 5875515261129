import React, { useContext, useEffect, useState } from 'react';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { NavContext } from "../../providers/nav_provider";
import { Button, Container, Navbar, Nav, NavDropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPlusCircle, faEdit } from '@fortawesome/free-solid-svg-icons'
import SortableItem from "../common/sortable_item";
import ExternalMediaModal from "../modals/external_media_modal";
import ExternalMediaItem from "../external_media/external_media_item";
import * as Sentry from "@sentry/browser";
import { set } from 'lodash';

export default function WorkoutExternalMediaList(props) {
    const { isOwnWorkout, handleAddMedia, items, mode, setIsDeepEditMode,
        setWorkout, userCanEdit, workout } = props;

    const { setDirty } = useContext(NavContext);

    const [activeId, setActiveId] = useState(null);
    const [currentMedia, setCurrentMedia] = useState(null);
    const [currentExternalMediaIndex, setCurrentExternalMediaIndex] = useState(null);
    const [isSortMode, setIsSortMode] = useState(null);
    const [showExternalMediaList, setShowExternalMediaList] = useState(true);
    const [showExternalMediaModal, setShowExternalMediaModal] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (currentMedia != null && currentExternalMediaIndex !== null) {
            setShowExternalMediaModal(true);
        }

    }, [currentMedia, currentExternalMediaIndex, setShowExternalMediaModal])

    const handleDragStart = (event) => {
        const { active } = event;

        setActiveId(active.id);
    }

    const handleCloseModal = () => {
        setShowExternalMediaModal(false);
        setCurrentMedia(null);
        setCurrentExternalMediaIndex(null);
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active?.id && over?.id && (active.id !== over.id)) {

            const newExternalMedia = (items) => {
                const oldIndex = items.findIndex(item => item.workout_external_medium_id === active.id);
                const newIndex = items.findIndex(item => item.workout_external_medium_id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            };

            let newWorkout = Object.assign({}, workout);
            newWorkout.external_media = newExternalMedia(workout.external_media);
            setWorkout(newWorkout);
        }
    }

    const handleDeleteExternalMedia = () => {
        let newExternalMedia = [...workout.external_media];

        newExternalMedia.splice(currentExternalMediaIndex, 1);
        let newWorkout = Object.assign({}, workout);
        newWorkout.external_media = newExternalMedia;

        setCurrentExternalMediaIndex(null);
        setDirty(true);
        setWorkout(newWorkout);
        setShowExternalMediaModal(false);
    };

    const handleMediaItemClick = (index) => {
        try {
            setCurrentMedia(workout.external_media[index]);
            setCurrentExternalMediaIndex(index);
        } catch (e) {
            Sentry.captureEvent(e);
        }
    };

    // select exercise from exercises based on matching id field
    const activeExternalMedia = () => {
        return items.find(item => item.workout_external_medium_id === activeId);
    }

    const renderDropdownAddButton = () => {
        const plusIcon = <span><FontAwesomeIcon icon={faPlusCircle} /></span>;
        if (userCanEdit && (mode === 'show')) {
            return (
                <NavDropdown.Item href="#" onClick={(event) => handleAddMedia(event)}>
                    <div className="d-flex justify-content-between"><span>Add More...</span>{plusIcon}</div>
                </NavDropdown.Item>
            );
        }
    }

    const renderDropdownSortButton = () => {
        if (['new', 'edit'].includes(mode) && items && (items?.length > 0)) {
            const sortIcon = <span><FontAwesomeIcon icon={faEdit} /></span>;

            return (
                <NavDropdown.Item onClick={(event) => {
                    event.preventDefault();
                    setIsSortMode(true);
                    setIsDeepEditMode(true);
                    setShowExternalMediaList(true);
                }}>
                    <div className="d-flex justify-content-between"><span>Sort</span>{sortIcon}</div>
                </NavDropdown.Item>
            )
        }
    }

    /* ToDo: add this back in
        const linkedIcon = <FontAwesomeIcon className="pe-2" icon={faLink} />;
        {workout.synced && linkedIcon}
    */
    const renderExternalMediaListOptions = () => {
        let ellipsisIcon = <span className="ellipsis-icon" style={{ fontSize: '1.5rem' }}><FontAwesomeIcon icon={faEllipsis} /></span>;

        return (
            <Navbar variant="dark" bg="dark" className="w-100">
                <Container fluid className="px-2">
                    <Nav.Item className="ms-1 text-light" href="#">Media</Nav.Item>
                    {isSortMode &&
                        <Nav.Item>
                            <Button variant='primary' size="sm"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setIsSortMode(false);
                                    setIsDeepEditMode(false);
                                }}>Done</Button>
                        </Nav.Item>
                    }
                    {!isSortMode && userCanEdit &&
                        <NavDropdown title={ellipsisIcon} id="exercise-list-dropdown" align="start" bg="dark"
                            drop="up" data-bs-theme="dark" className="text-light dropdown-dark">
                            {(items?.length > 1) && renderDropdownSortButton()}
                            {renderDropdownAddButton()}
                        </NavDropdown>
                    }
                    <Navbar.Toggle type="button" style={{ display: 'flex' }} aria-label="Toggle navigation" className="dropleft" bsPrefix="sws-navbar-toggler"
                        onClick={() => setShowExternalMediaList(!showExternalMediaList)} >
                        <div className="d-flex justify-content-between">
                            <div className="navbar-toggler-icon"></div>
                        </div>
                    </Navbar.Toggle>
                </Container>
            </Navbar>
        );
    }

    /* ToDo: clear index, currentMedia on modal close */
    const renderExternalMediaModal = () => {
        return (
            <ExternalMediaModal {...props}
                handleCloseModal={handleCloseModal}
                handleDelete={handleDeleteExternalMedia}
                hideAddToWorkout={isOwnWorkout}
                media={currentMedia}
                showExternalMediaModal={showExternalMediaModal}
            />
        )
    }

    const sortableWrapperClasses = (isActive) => {
        let classes = 'p-0 col-xl-3 col-md-4 col-6';
        classes = isActive ? `${classes} bg-secondary` : classes;

        return classes;
    }

    const dndLayout = () => {
        if (!items) return null;

        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}>
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items?.map((item, index) => (
                        <SortableItem id={item.workout_external_medium_id}
                            classes={sortableWrapperClasses()}
                            disabled={!isSortMode}
                            handleSortDisabledClick={handleMediaItemClick}>

                            <ExternalMediaItem
                                handleMediaClick={handleMediaItemClick}
                                index={index}
                                item={item}
                            />
                        </SortableItem>
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeId &&
                        <div className={sortableWrapperClasses(true)}>
                            <ExternalMediaItem {...props} item={activeExternalMedia()} />
                        </div>
                    }
                </DragOverlay>
            </DndContext>
        )
    }

    return (
        <>
            {renderExternalMediaListOptions()}
            <Navbar.Collapse in={showExternalMediaList} className="px-2">
                <Row>{dndLayout()}</Row>
            </Navbar.Collapse>
            {renderExternalMediaModal()}
        </>
    )

    /* ToDo: bring this back:
            {
                multiSelect &&
                <NavGroupingButtons
                    {...props}
                    setSelectedExercises={setSelectedItems}
                />
            }
    */
}
