import React from 'react';
import {SwsAnalytics} from "../../services/sws_analytics";
import {Form} from 'react-bootstrap';
import API from '../../utils/api';
import * as ActiveStorage from "@rails/activestorage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import ExerciseAnimatedImage from "./exercise_animated_image";
import ReactPlayer from "react-player";
import {Config} from "../../utils/config";
import {isSafari} from 'react-device-detect';
import * as Sentry from "@sentry/browser";
import { UserService } from "../../services/user_service";

const ExerciseAnimatedImageUploading = () => {
    const circleNotch = <FontAwesomeIcon icon={faCircleNotch} size="3x" spin/>;

    return (
        <>
            <div className="d-flex text-center">
                <div className="p-2 loading-wrapper">
                    {circleNotch}
                    <div>
                        Uploading
                    </div>
                </div>
            </div>
        </>
    );
};

class ExerciseMediaForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };

        ActiveStorage.start();
    };

    handleMediaUpload = (file) => {
        const {handleTempUploadFile, updateExerciseMedium, setIsUploading} = this.props;
        const config = Config.get();
        let contentType = null;

        if (file.size > (config.maxFileSizes.exerciseImageMb * 1024 * 1024)) {
            this.setState({error: `Filesize limited to ${config.maxFileSizes.exerciseImageMb} mb`});
            return false;
        }

        /*
        const fileParams = file.type.split("/");

        if (!fileParams.length || fileParams[0] !== 'video') {
            this.setState({error: `Only video filetypes are supported`});
            return false;
        }
         */

        return new Promise((resolve, reject) => {
            const authToken = UserService.authToken;
            const requestHeaders = { 'Authorization': authToken };

            const upload = new ActiveStorage.DirectUpload(
                file,
                `${config.apiHost}/rails/active_storage/direct_uploads`,
                this,
                requestHeaders
            );

            setIsUploading(true);
            handleTempUploadFile(URL.createObjectURL(file));

            SwsAnalytics.event({
                category: 'Video',
                action: 'Upload'
            });

            upload.create((error, blob) => {
                if (error) {
                    alert('something went wrong with upload!');
                } else {
                    resolve(blob);
                }
            });
        }).then(result2 => {
            // API call to create ExerciseVideo

            SwsAnalytics.event({
                category: 'Video',
                action: 'Create'
            });

            if (result2.content_type) {
                contentType = result2.content_type;
            }

            return API.post(`${config.apiBase}/exercise_media`, {...result2});
        }, error => {
            Sentry.captureEvent("upload-Promise2 got error: " + JSON.stringify(error));

        }).then(data => {
            if (data === undefined) {
                this.setState({error: "Image failed to upload"});
                SwsAnalytics.event({
                    category: 'VideoUpload',
                    action: 'Failed'
                });
            } else {
                this.setState({error: null});
                setIsUploading(false);
                updateExerciseMedium({
                    exerciseMediumId: data.id,
                    exerciseMediumType: data.medium_type,
                    exerciseMediumContentType: contentType
                });
                SwsAnalytics.event({
                    category: 'Video',
                    action: 'Saved'
                });
            }
        }, error => {
            // ToDo: Sentry this
        });
    };

    render = () => {
        const {
            exercise, exerciseMediumId, exerciseMediumContentType, exerciseMediumType,
            tempUploadFile, isUploading, mode
        } = this.props;

        if (isUploading) {
            return (<ExerciseAnimatedImageUploading/>);

        } else if (exerciseMediumId === null) {

            // new exercise
            return (
                <>
                    {((mode === 'edit') || (mode === 'new')) && this.renderInternalForm()}
                </>
            );

        } else if (tempUploadFile) {
            if (exerciseMediumType === 'image') {
                return (
                    <div className="d-flex justify-content-center bg-dark">
                        <img className="image-preview" alt="animated exercise" src={tempUploadFile}/>
                    </div>
                );
            }
            // new video has been uploaded, show it in react player

            if ((exerciseMediumContentType === 'video/quicktime') && !isSafari) {
                return (
                    <div className="d-flex justify-content-center">
                        <p><i><span className="text-danger">
                            This image cannot be previewed in this browser, but will attempt to be converted
                        </span></i></p>
                    </div>
                )
            } else {
                return (<ReactPlayer
                    url={tempUploadFile}
                    controls={false}
                    loop={true}
                    muted
                    playing
                    width="100%"
                    height="100%"
                />);
            }

        } else if (exercise.src) {
            return (
                <>
                    <div className="d-flex justify-content-center bg-dark">
                        <img className="image-preview" alt="animated exercise"
                             src={`${config.s3Basepath}/${exercise.src}`}/>
                    </div>
                    {(mode === 'edit') && this.renderInternalForm()}
                </>
            );
        } else {
            return (
                <>
                    <ExerciseAnimatedImage size="md" {...this.props} />
                    {(mode === 'edit') && this.renderInternalForm()}
                </>
            );
        }
    }

    renderInternalForm = () => {
        const {error} = this.state;

        return (
            <>
                <div className="input-group">
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control type="file"
                                      onChange={e => {
                                          this.handleMediaUpload(e.target.files[0])
                                      }}
                        />
                    </Form.Group>
                </div>
                {error &&
                <div className="input-group">
                    <div className="w-100 text-center mt-1 text-danger">
                        {error}
                    </div>
                </div>
                }
            </>
        )
    };
}

export default ExerciseMediaForm;
