import React from 'react';
import {Row, Col, Form, Button, Container, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCog,
    faExpand,
    faCompress,
    faPlay,
    faPause,
    faStepForward
} from '@fortawesome/free-solid-svg-icons'
import ExercisePerform from "../exercises/exercise_perform";
import ExerciseThumb from "../exercises/exercise_thumb";
import AlertModal from "../modals/alert_modal";
import PerformUtils from "../../utils/perform_utils";
import Formatter from "../../utils/formatter";
import SwsVideoPlayer from '../players/sws_video_player';
import ConfigWorkoutPerformModal from "../modals/config_workout_perform_modal";
import ObjectDefaults from "../../utils/object_defaults";
import ImageUploadObject from "../common/image_upload_object";
import {Config} from "../../utils/config";
import NavWrapper from "../nav/nav_wrapper";

export default class WorkoutPerform extends React.PureComponent {
    COUNTDOWN = 6;

    // ToDo: if there are no rest sections, then replace the 'start' sound with an 'interval' one

    constructor(props) {
        super(props);

        //audiophile: 'silence', // 'silence', 'start', 'midpoint', 'countdown', 'done'
        this.state = {
            audioLoaded: false,
            configModalMode: 'perform',
            currentExercise: null,
            currentNote: '',
            elapsedSecs: 0,
            error: false,
            exerciseIndex: 0,
            firstMainSyncPlayerLoad: true,
            isPlayerBuffering: false,
            layout: 'auto',
            mainPlayerSecs: null,
            nonSyncedPlayerIsPlaying: false,
            originalRound: 0,
            originalRest: 0,
            originalSets: 1,
            // cue_perform is the pause before the main workout starts - allows the user to get ready
            performState: 'init', // 'init', 'pic', 'precursor', 'warmup', 'loading_perform', 'cue_perform', 'performing', 'paused', 'cooldown', 'done'
            playerBufferingJustFinished: false,
            playSounds: false,
            prevExercises: [],
            roundDuration: 0,
            roundState: 'round', // 'rest', 'round', 'done'
            segmentIndex: 0,
            sets: 1,
            setIndex: 0,
            sizeRatio: 'small-media',
            shareImage: ObjectDefaults.image(),
            showConfigModal: false,
            showFilmstrip: true,
            syncedPlayerFirstPlay: true,
            syncedPlayerIsFullFrame: false,
            syncedVideoDuration: 0,
            timed: false,
            totalRounds: 0,
            workoutId: null
        };

        if (this.props.workoutPerform) {
            const defaults = PerformUtils.systemDefaults();

            this.state.timed = this.props.workoutPerform.timed;
            this.state.playSounds = (this.props.workoutPerform.playSounds !== undefined) ? this.props.workoutPerform.playSounds : defaults.playSounds;
            this.state = Object.assign(this.state, this.stateFromWorkoutPerform());
        }

        // ToDo: this only handles when there's no precursor, or warmup video
        if (this.props.workout && this.props.workout.main_link && Config.get().iosDevice &&
            !this.props.workout.warmup_link && !this.props.workout.precursor_link &&
            (!this.props.workoutImage || !this.props.workoutImage.src || !this.props.workoutImage.src.length)) {
            this.state.performState = 'loading_perform';
        }
        this.swsVideoPlayerRef = React.createRef();
        this.swsMediaPlayerRef = React.createRef();
        this.ctx = new AudioContext();
        this.startBellAudioBuffer = null;
        this.endBellAudioBuffer = null;

        fetch(`${Config.get().s3Basepath}/assets/sounds/start_bell.wav`)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => this.ctx.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                this.startBellAudioBuffer = audioBuffer;
            })

        fetch(`${Config.get().s3Basepath}/assets/sounds/three_bell.wav`)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => this.ctx.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                this.endBellAudioBuffer = audioBuffer;
            })

        this.timer = null;
    };

    componentWillUnmount = () => {
        this.setState({performState: 'init'});
    }

    stateFromWorkoutPerform = () => {
        const {exercises, workoutPerform} = this.props;

        let updates = {
            elapsedSecs: 0,
            exerciseIndex: 0,
            performState: 'init',
            segmentIndex: 0
        };

        updates['currentNote'] = workoutPerform?.exercises?.[0]?.note || '';

        if (exercises?.[0]?.is_group) {
            updates['currentNote'] = workoutPerform?.exercises?.[0]?.exercises?.[0]?.note || '';
        }

        if (workoutPerform.timed) {
            const roundDuration = PerformUtils.calcDuration('round', workoutPerform, 0, 0);
            const restDuration = PerformUtils.calcDuration('rest', workoutPerform, 0, 0);

            updates = {
                originalSets: workoutPerform.sets,
                sets: PerformUtils.calcDuration('sets', workoutPerform, 0, 0),
                originalRound: workoutPerform.round,
                roundDuration: roundDuration,
                roundSecs: roundDuration,
                originalRest: workoutPerform.rest,
                restDuration: restDuration,
                restSecs: restDuration
            }
        }
        return updates;
    };

    componentDidMount() {
        const {navigate, setAlert} = this.props;

        //this.props.history.push(window.location.href);
        window.onpopstate = function () {
            if (this.state && this.state.performState === 'performing') {
                setAlert({title: null, confirmFn: () => navigate(-1), message: 'Quit Workout?', show: true})
            }
        };
    };

    static getDerivedStateFromProps(props, prevState) {
        let updates = {};
        let dirty = false;

        if ((!props.workoutPerform) || (!props.workout)) return null;

        /*
        if ((props.workoutPerform.timed !== prevState.timed) ||
            (props.workoutPerform.sets !== prevState.originalSets) ||
            (props.workoutPerform.round !== prevState.originalRound) ||
            (props.workoutPerform.rest !== prevState.originalRest)) {
            updates = Object.assign(updates, {
                originalSets: props.workoutPerform.sets,
                originalRound: props.workoutPerform.round,
                originalRest: props.workoutPerform.rest,
                timed: props.workoutPerform.timed,
                sets: PerformUtils.calcDuration('sets', props.workoutPerform, prevState.exerciseIndex, prevState.segmentIndex),
                roundDuration: PerformUtils.calcDuration('round', props.workoutPerform, prevState.exerciseIndex, prevState.segmentIndex),
                roundSecs: PerformUtils.calcDuration('round', props.workoutPerform, prevState.exerciseIndex, prevState.segmentIndex),
                restDuration: PerformUtils.calcDuration('rest', props.workoutPerform, prevState.exerciseIndex, prevState.segmentIndex),
                restSecs: PerformUtils.calcDuration('rest', props.workoutPerform, prevState.exerciseIndex, prevState.segmentIndex)
            })
            dirty = true;
        }

         */

        if (prevState.performState === 'init') {
            if (props.workoutImage && props.workoutImage.src && props.workoutImage.src.length) {
                updates = Object.assign(updates, {
                    performState: 'pic'
                });
            } else if (props.workout.precursor_link) {
                updates = Object.assign(updates, {
                    performState: 'precursor'
                });
                dirty = true;
            } else if (props.workout.warmup_link) {
                updates = Object.assign(updates, {
                    performState: 'warmup'
                });
                dirty = true;
            }
        }

        // Set first currentExercise
        if ((prevState.currentExercise === null) && props.exercises.length) {
            let newExercise = props.exercises[0];
            let newNote = props.workoutPerform.exercises[0].note || '';

            if (newExercise.is_group) {
                newExercise = newExercise.segments[0];
                newNote = props.workoutPerform?.exercises?.[0]?.exercises?.[0]?.note || '';
            }

            updates = Object.assign(updates, {
                currentExercise: newExercise,
                currentNote: newNote
            });
            dirty = true;
        }

        if (dirty) {
            return updates;
        } else {
            return null;
        }
    };

    exerciseSyncStart = (index) => {
        const {exercisePerforms, workout} = this.props;

        if (workout.synced && exercisePerforms &&
            (index < exercisePerforms.length) && exercisePerforms[index].start) {
            return exercisePerforms[index].start;
        } else {
            return null
        }
    };

    gotoNextPerformState = () => {
        const {workout} = this.props;
        const {showConfigModal, performState} = this.state;
        let newPerformState = performState;
        let newShowConfigModal = showConfigModal;

        switch (performState) {
            case 'pic':
                if (workout.precursor_link) {
                    newPerformState = 'precursor';
                } else if (workout.warmup_link) {
                    newPerformState = 'warmup';
                } else if (Config.get().iosDevice) {
                    newPerformState = 'cue_perform';
                } else {
                    newPerformState = 'loading_perform';
                    newShowConfigModal = true;
                }
                break;
            case 'precursor':
                if (workout.warmup_link) {
                    newPerformState = 'warmup';
                } else if (Config.get().iosDevice) {
                    newPerformState = 'cue_perform';
                } else {
                    newPerformState = 'loading_perform';
                    newShowConfigModal = true;
                }
                break;
            case 'warmup':
                if (Config.get().iosDevice) {
                    newPerformState = 'cue_perform';
                } else {
                    this.startTimer();
                    newPerformState = 'loading_perform';
                    newShowConfigModal = true;
                }
                break;
            case 'performing':
                if (workout.synced) {
                    this.stopTimer();
                    if (workout.cooldown_link) {
                        newPerformState = 'cooldown';
                        this.playSound('done');
                    } else {
                        newPerformState = 'done';
                        this.playSound('done');
                    }
                }
                break;
            case 'cooldown':
                this.stopTimer();
                newPerformState = 'done';
                break;
            default:
                break;
        }
        this.setState({performState: newPerformState, showConfigModal: newShowConfigModal});
    };

    handleConfirmFunction = () => {
        const {navigate} = this.props;

        navigate(-1);
    }

    handleBrandClick = (event) => {
        const {setAlert} = this.props;
        const {performState} = this.state;

        event.preventDefault();
        event.stopPropagation();

        if ((performState === 'performing') || ['precursor', 'warmup', 'cooldown'].includes(performState)) {
            setAlert({title: null, confirmFn: () => this.handleConfirmFunction, message: 'Quit Workout?', show: true});
        } else {
            this.handleDone();
        }
    };

    handleDone = () => {
        const {mode, navigate, setIsPerform, workoutId} = this.props;

        if (mode === 'share') {
            navigate(`/workouts/${workoutId}/shares`);
        } else {
            setIsPerform(false);
        }
    };

    handleExerciseClick = (index) => {
        const {exercises, workout, workoutPerform} = this.props;
        let nextNote = workoutPerform.exercises[index].note || '';

        if (exercises[index].is_group) {
            nextNote = workoutPerform?.exercises[index]?.exercises?.[0]?.note || '';
        }

        if (workout.synced) {
            const start = this.exerciseSyncStart(index);

            if (start && this.swsVideoPlayerRef && this.swsVideoPlayerRef.current) {
                this.swsVideoPlayerRef.current.seekTo(start);
            }

            this.setState({
                exerciseIndex: index,
                currentExercise: exercises[index],
                currentNote: nextNote,
                roundSecs: -1,
                isPlayerBuffering: true
            });
        } else {
            const newCurrentExercise = exercises[index].is_group ? exercises[index].segments[0] : exercises[index];
            const newRoundDuration = PerformUtils.calcDuration('round', workoutPerform, index, 0);

            this.setState({
                currentExercise: newCurrentExercise,
                currentNote: nextNote,
                exerciseIndex: index,
                segmentIndex: 0,
                setIndex: 0,
                roundDuration: newRoundDuration,
                roundSecs: newRoundDuration,
                roundState: 'round',
                restDuration: PerformUtils.calcDuration('rest', workoutPerform, index, 0),
                sets: PerformUtils.calcDuration('sets', workoutPerform, index)
            });
        }
    };

    handleNextButton = () => {
        const {exercises, workout, workoutPerform} = this.props;
        const {exerciseIndex, performState} = this.state;

        //if (this.swsMediaPlayerRef?.current) {
        //    this.swsMediaPlayerRef.current.getInternalPlayer().nextVideo();
        //}

        if (['performing', 'paused'].includes(performState)) {
            if (workout.synced) {
                const newExerciseIndex = exerciseIndex + 1;
                if (newExerciseIndex < exercises.length) {
                    const start = this.exerciseSyncStart(newExerciseIndex);

                    if (start && this.swsVideoPlayerRef && this.swsVideoPlayerRef.current) {
                        this.swsVideoPlayerRef.current.seekTo(start);
                    }

                    this.setState({
                        exerciseIndex: newExerciseIndex,
                        currentExercise: exercises[newExerciseIndex],
                        currentNote: workoutPerform.exercises[newExerciseIndex].note || '',
                        isPlayerBuffering: true,
                        roundSecs: -1
                    });
                } else {
                    this.gotoNextPerformState();
                }

            } else {
                let nextState = this.nextState();

                if (nextState.roundState !== 'done') {
                    nextState.roundSecs = nextState.roundDuration;
                }
                this.setState(nextState);
            }
        } else {
            this.gotoNextPerformState();
        }
    };

    handlePauseWorkout = () => {
        this.stopTimer();
        this.setState({performState: 'paused'});
    };

    handleResumeWorkout = () => {
        this.startTimer();
        this.setState({performState: 'performing'});
    };

    handleStartMain = () => {
        this.startTimer();
        this.playSound('start');
        this.setState({performState: 'performing'});
    };

    handleMainPlaying = () => {
        this.startTimer();
        this.setState({performState: 'performing'});
    };

    handleRestart = () => {
        const newState = Object.assign({},
            this.stateFromWorkoutPerform(),
            {showConfigModal: false, performState: 'init'})
        this.setState(newState);
    };

    handleConfigDone = () => {
        const {exercises, setIsPerform} = this.props;
        const {performState} = this.state;

        if (!exercises || (exercises.length === 0) || (performState === 'done')) {
            setIsPerform(false);
        } else if ((performState === 'init') || (performState === 'pic')) {
            this.setState({showConfigModal: false});
        } else {
            this.setState({showConfigModal: false});
        }
    };

    handleStartWorkout = () => {
        const {workout} = this.props;

        this.playSound('start');

        this.stopTimer();

        if (workout.precursor_link) {
            this.setState({performState: 'precursor'});
        } else if (workout.warmup_link) {
            this.setState({performState: 'warmup'});
        } else {
            this.startTimer();
            this.setState({performState: 'performing'});
            if (Config.get().iosDevice && workout.main_link && this.swsVideoPlayerRef && this.swsVideoPlayerRef.current) {
                let start = this.exerciseSyncStart(0);
                start = start ? start : 0;
                this.swsVideoPlayerRef.current.seekTo(start);
            }
        }
    };
    handleToggleFilmstrip = () => {
        const {showFilmstrip} = this.state;

        this.setState({showFilmstrip: !showFilmstrip});
    };

    handleUpdateState = (update) => {
        const new_state = Object.assign({}, this.state, update);
        this.setState(new_state);
    };

    isInitOrPerforming = () => {
        const {performState} = this.state;

        return (['init', 'loading_perform', 'cue_perform', 'performing', 'paused'].includes(performState));
    };

    isWarmup = () => {
        const {workout} = this.props;
        const {performState} = this.state;

        return workout.warmup_link && (['warmup'].includes(performState));
    };

    isSingleVideoWorkout = () => {
        const {exercises, workout} = this.props;
        return (exercises.length === 0) && workout.main_link;
    }

    isPerformRestartEnabled = () => {
        const {performState} = this.state;
        const {workoutPerform} = this.props;

        return ((performState === 'done') && (workoutPerform.timed || !workoutPerform.synced));
    };

    isPic = () => {
        const {workoutImage} = this.props;
        const {performState} = this.state;

        return workoutImage && (['pic'].includes(performState));
    };

    isPrecursor = () => {
        const {workout} = this.props;
        const {performState} = this.state;

        return workout.precursor_link && (['precursor'].includes(performState));
    };

    isCooldown = () => {
        const {workout} = this.props;
        const {performState} = this.state;

        return workout.cooldown_link && (['cooldown'].includes(performState));
    };

    // Eliminate the resume feature from youtube
    mainPerformLink = () => {
        const {workout} = this.props;

        if (!workout.main_link) return null;

        let addStart = workout.main_link.match(/youtube/) && !workout.main_link.match(/start/);

        return (addStart ? `${workout.main_link}&t=0` : workout.main_link);
    };

    playerOnBufferEnd = () => {
        this.setState({isPlayerBuffering: false, playerBufferingJustFinished: true});
    };

    playerOnDuration = (duration) => {
        this.setState({syncedVideoDuration: duration});
    };

    playerOnProgress = (progress) => {
        const {exercises, exercisePerforms, workout} = this.props;
        const {
            exerciseIndex, isPlayerBuffering, nonSyncedPlayerIsPlaying,
            playerBufferingJustFinished, syncedVideoDuration
        } = this.state;
        // This creates a gate between the time the buffering has finished, and the currentExercise has caught up
        // We don't want to play a start bell in that case
        let updates = {playerBufferingJustFinished: false};

        // Cannot handle grouped exercises - yet
        // These have to be saved as sorted.  Anything that doesn't have a start time cannot be performed

        if (!nonSyncedPlayerIsPlaying && !workout.synced) return true;

        if (!isPlayerBuffering && progress && progress.playedSeconds && exercisePerforms) {
            updates = Object.assign(updates, {
                mainPlayerSecs: progress.playedSeconds, roundSecs: -1
            });

            exercisePerforms.find((exercisePerform, i) => {
                let nextExerciseStart = (i < (exercisePerforms.length - 1)) ?
                    Number(exercisePerforms[i + 1].start) : syncedVideoDuration;

                if ((progress.playedSeconds >= Number(exercisePerform.start)) &&
                    (progress.playedSeconds < nextExerciseStart)) {
                    updates = Object.assign(updates, {
                        currentExercise: exercises[i],
                        currentNote: exercisePerforms[i].note || '',
                        exerciseIndex: i,
                        roundSecs: nextExerciseStart - progress.playedSeconds
                    });

                    // Don't play sound if player just seeked to this further position
                    if ((i > exerciseIndex) && !playerBufferingJustFinished) {
                        this.playSound('start');
                    }
                    return true;
                }
                return false;
            });
        }

        this.setState(updates);
    };

    playerOnReady = () => {
        const {performState} = this.state;

        if ((performState === 'loading_perform') && this.swsVideoPlayerRef && this.swsVideoPlayerRef.current) {
            let start = this.exerciseSyncStart(0);
            start = start ? start : 0;

            this.swsVideoPlayerRef.current.seekTo(start);
        }
    };

    playSound = (name) => {
        const {playSounds} = this.state;

        if (!playSounds) return false;

        let bufferSource = this.ctx.createBufferSource();

        switch (name) {
            case 'start':
                bufferSource.buffer = this.startBellAudioBuffer;
                break;
            case 'done':
                bufferSource.buffer = this.endBellAudioBuffer;
                break;
            default:
                return;
        }
        bufferSource.connect(this.ctx.destination);
        bufferSource.start();
    };

    render = () => {
        const {exercises, workout} = this.props;
        const {
            configModalMode,
            exerciseIndex,
            layout,
            mainPlayerSecs,
            performState,
            playSounds,
            sizeRatio,
            shareImage,
            showConfigModal,
            timed
        } = this.state;

        if (workout == null) return "";

        return (
            <>
                {this.renderHeader()}
                <Container fluid className="workout-perform">
                    {this.isPic() && this.renderImage()}
                    {this.isPrecursor() && this.renderVideoPlayer(workout.precursor_link)}
                    {this.isWarmup() && this.renderVideoPlayer(workout.warmup_link)}
                    {this.isCooldown() && this.renderVideoPlayer(workout.cooldown_link)}
                    {this.isSingleVideoWorkout() && this.isInitOrPerforming() && this.renderSyncedVideoPlayer()}
                    {!this.isSingleVideoWorkout() && this.isInitOrPerforming() && this.renderInitOrPerforming()}
                    {performState === 'done' && this.renderDone()}
                </Container>
                <ConfigWorkoutPerformModal {...this.props}
                                           exercises={exercises}
                                           handleDone={this.handleConfigDone}
                                           handleExerciseClick={this.handleExerciseClick}
                                           handleRestart={this.handleRestart}
                                           highlightExerciseIndex={exerciseIndex}
                                           layout={layout}
                                           mainPlayerSecs={mainPlayerSecs}
                                           mode={configModalMode}
                                           performState={performState}
                                           playSounds={playSounds}
                                           renderFooter={this.renderConfigFooter}
                                           setLayout={(layout) => this.setState({layout: layout})}
                                           setSizeRatio={(sizeRatio) => this.setState({sizeRatio: sizeRatio})}
                                           sizeRatio={sizeRatio}
                                           shareImage={shareImage}
                                           show={showConfigModal}
                                           showExercises={!['precursor', 'warmup'].includes(performState)}
                                           timed={timed}
                                           title="Configure Workout:"
                                           updateConfigWorkoutModalState={this.handleUpdateState}
                                           updateShareImageState={this.handleUpdateShareImageState}
                />
            </>
        );
    };
    /*
    {this.isNotVideo() && showFilmstrip &&
    <Nav className="fixed-bottom navbar-light bg-light workout-bottom-nav">
    {
        this.renderFilmstrip(elapsedSecs, totalSecs)
    }
    </Nav>
    }
    */

    renderDone = () => {
        const {workout} = this.props;
        const {elapsedSecs} = this.state;

        return (
            <div className="card bg-dark text-white text-shadow">
                <div className="card-img-overlay">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title text-white text-shadow">
                            <div>
                                {workout.title}
                            </div>
                            <br/>
                            <div>
                                Complete
                            </div>
                        </h5>
                        <h5 className="card-title text-white text-shadow">
                            {Formatter.toHHMMSS(elapsedSecs)}
                        </h5>
                    </div>
                </div>
            </div>
        );
    };


    renderHeader = () => {
        const {performState, syncedPlayerIsFullFrame} = this.state;
        const {workout} = this.props;

        const showExpandContract = ['cue_perform', 'performing', 'paused'].includes(performState) &&
            workout.synced && !this.isSingleVideoWorkout();

        const showCog = !this.isSingleVideoWorkout() && ['init',
            'loading_perform',
            'cue_perform',
            'performing',
            'paused',
            'cooldown',
            'done'].includes(performState);

        return (
            <>
                <NavWrapper>
                    <ul className="navbar-nav flex-row me-auto w-100 justify-content-between"
                        style={{fontSize: '1.5rem'}}>
                        <li className="nav-item">
                            <button className="navbar-brand pb-2 btn-borderless" onClick={(event) => {
                                this.handleBrandClick(event)
                            }}>
                                <div className="logo">
                                    <img alt="fastfit_logo" src="/assets/images/fastfit_gx_logo_black.png"/>
                                </div>
                            </button>
                        </li>
                        {this.renderTimeOrPerform()}
                        {this.renderPlayButtons()}
                        {showExpandContract &&
                            <li className="nav-item">
                                {!syncedPlayerIsFullFrame &&
                                    <Button onClick={() =>
                                        this.setState({syncedPlayerIsFullFrame: true})}
                                            variant="sm"
                                            className="ctw-perform-button">
                                        <FontAwesomeIcon icon={faExpand}/>
                                    </Button>
                                }
                                {syncedPlayerIsFullFrame &&
                                    <Button onClick={() =>
                                        this.setState({syncedPlayerIsFullFrame: false})}
                                            variant="sm"
                                            className="ctw-perform-button">
                                        <FontAwesomeIcon icon={faCompress}/>
                                    </Button>
                                }
                            </li>
                        }
                        {showCog &&
                            <li className="nav-item">
                                <Button onClick={() => this.setState({showConfigModal: true})}
                                        variant="sm"
                                        className="ctw-perform-button">
                                    <FontAwesomeIcon icon={faCog}/>
                                </Button>
                            </li>
                        }
                    </ul>
                </NavWrapper>
            </>
        );
    };

    renderImage = () => {
        const {workoutImage} = this.props;

        return (
            <ImageUploadObject imageObject={workoutImage}/>
        )
    };

    renderTimeOrPerform = () => {
        const {elapsedSecs, performState} = this.state;

        let label = null;

        switch (performState) {
            case 'precursor':
                label = 'Intro';
                break;
            case 'warmup':
                label = 'Warmup';
                break;
            case 'performing':
            case 'paused':
                label = Formatter.toHHMMSS(elapsedSecs);
                break;
            case 'cooldown':
                label = 'Cooldown';
                break;
            case 'done':
                label = Formatter.toHHMMSS(elapsedSecs);
                break;
            default:
                label = '';
        }

        if (label === null) return null;
        //<li className="nav-item" style={{width: '3rem'}}>

        return (
            <li className="nav-item">
                <div className="text-light pt-1">
                    {label}
                </div>
            </li>
        );
    };

    renderConfigFooter = () => {
        const {performState} = this.state;

        return (
            <Modal.Footer className="justify-content-between">
                {this.isPerformRestartEnabled() &&
                    <Button variant="secondary" onClick={() => this.handleRestart()}>
                        Restart
                    </Button>
                }
                {(performState !== 'done') &&
                    <Button variant="danger" onClick={() => {
                        this.handleDone();
                    }}>
                        Quit Workout
                    </Button>
                }
                <Button type="submit" onClick={() => this.handleConfigDone()}>
                    {(performState === 'Done') ? 'Quit Workout' : 'Close Menu'}
                </Button>
            </Modal.Footer>
        )
    }

    renderToggler = () => {
        return (
            <li className="nav-item">
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#enhancedCtrls"
                        aria-controls="enhancedCtrls" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </li>
        );
    };

    renderExercisePerform = () => {
        const {exercises} = this.props;
        const {
            currentExercise,
            exerciseIndex,
            layout,
            performState,
            roundState,
            segmentIndex,
            setIndex,
            sets
        } = this.state;
        const {increment, workoutPerform} = this.props;

        return (
            <ExercisePerform
                {...this.props}
                exercise={currentExercise}
                exercises={exercises}
                exerciseIndex={exerciseIndex}
                increment={increment}
                performState={performState}
                segmentIndex={segmentIndex}
                sets={sets}
                setIndex={setIndex}
                showTitle={true}
                roundState={roundState}
                timed={workoutPerform.timed}
                renderExerciseHeader={this.renderExerciseHeader}
                renderExerciseFooter={this.renderExerciseFooter}
            >
                {(layout === 'corner') && this.renderExternalMediaPlayer()}
            </ExercisePerform>
        );

    }

    renderInitOrPerforming = () => {
        const {layout, sizeRatio} = this.state;
        const {workout} = this.props;

        let mediaSm = 6;
        let exerciseSm = 6;
        if (sizeRatio === 'small-media') {
            mediaSm = 4;
            exerciseSm = 8;
        } else if (sizeRatio === 'small-exercise') {
            mediaSm = 8;
            exerciseSm = 4;
        }

        const mediaLink = workout?.external_media?.[0]?.link;

        return (
            <>
                <div className="d-block d-sm-none">
                    <div className="text-dark">
                        {this.renderExerciseHeader()}
                    </div>
                </div>
                {(layout === 'corner') &&
                    <Row>
                        <Col xs={12}>{this.renderExercisePerform()}</Col>
                    </Row>
                }
                {(layout === 'auto') &&
                    <>
                        <Row className="align-items-center">
                            <Col xs={12} sm={exerciseSm}>{this.renderExercisePerform()}</Col>
                            <div className='d-block d-sm-none p-2'></div>
                            {mediaLink && <Col xs={12} sm={mediaSm}>
                                <div className='player-wrapper'>
                                    {this.renderExternalMediaPlayer()}
                                </div>
                            </Col>
                            }
                        </Row>
                    </>
                }
                {false && (layout === 'stacked') &&
                    <>
                        <Row className="align-items-center">
                            <Col xs={12}>{this.renderExercisePerform()}</Col>
                        </Row>
                        {mediaLink &&
                            <div className='player-wrapper'>
                                {this.renderExternalMediaPlayer()}
                            </div>
                        }
                    </>
                }
                {false && (layout === 'side-by-side') &&
                    <Row>
                        {mediaLink &&
                            <>
                                <Col xs={6}>{this.renderExercisePerform()}</Col>
                                <Col xs={6}>{this.renderExternalMediaPlayer()}</Col>
                            </>
                        }
                        {!mediaLink &&
                            <Col xs={12}>{this.renderExercisePerform()}</Col>
                        }
                    </Row>
                }
                <div className="d-block d-sm-none">
                    <div className="text-dark">
                        {this.renderExerciseFooter()}
                    </div>
                </div>
            </>
        );
    };

    renderExerciseHeader = (textClasses) => {
        const {exercises, workout} = this.props;
        const {
            currentExercise, currentNote, exerciseIndex, performState, roundSecs,
            roundState, restSecs, timed
        } = this.state;

        const countdown = (roundState === 'round' ? Number(roundSecs) : Number(restSecs));
        let lHeaders = [];

        if (['init', 'loading_perform', 'cue_perform'].includes(performState)) {
            lHeaders.push(workout.title);
            if (workout.total_secs) {
                lHeaders.push(Formatter.toHHMMSS(workout.total_secs));
            }
        }

        let rHeaders = [];

        if (roundState === 'rest') {
            lHeaders.push('Coming Up');
        }

        if (currentExercise && currentExercise.title) {
            lHeaders.push(`${exerciseIndex + 1}/${exercises.length}: ${currentExercise.title}`);
        }

        // ToDo: handle exercise.is_group notes
        if (currentNote && currentNote.length) {
            lHeaders.push(currentNote);
        }

        if (timed || workout.synced) {
            if ((countdown < 0) || isNaN(countdown)) {
                lHeaders.push('--:--:--');
            } else {
                lHeaders.push(Formatter.toMMSS(countdown, {zeroPad: true}));
            }
        }

        let headerRows = [];
        let index = 0;

        while ((lHeaders[index] || rHeaders[index]) && index < 6) {
            headerRows.push(
                <div key={`header-row-${index}`}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className={textClasses}>
                            {lHeaders[index] || ''}
                        </div>
                        <div className={textClasses}>
                            {rHeaders[index] || ''}
                        </div>
                    </div>
                </div>
            )
            index++;
        }
        return (
            <>
                {headerRows}
            </>
        );
    };

    renderExerciseFooter = () => {
        const {exercises, exercisePerforms} = this.props;
        const {exerciseIndex, segmentIndex, sets, setIndex} = this.state;
        const {workoutPerform} = this.props;

        if (exercises.length === 0) return null;

        // ToDo: a function to determine this
        const numberOfSets = exercisePerforms[exerciseIndex].sets ?
            exercisePerforms[exerciseIndex].sets : workoutPerform.sets;

        let bottomText = '';

        if (sets > 1) {
            bottomText += `Set: ${setIndex + 1}/${numberOfSets}`;
        }

        if (exercises[exerciseIndex].is_group) {
            const numberOfSegments = exercises[exerciseIndex].segments.length;

            bottomText += (bottomText.length > 0) ? ', ' : ''
            bottomText += `Segment: ${segmentIndex + 1}/${numberOfSegments}`;
        }

        return (
            <>
                {bottomText}
            </>
        )
    };

    renderPlayButtons = () => {
        const {nonSyncedPlayerIsPlaying, performState} = this.state;

        return (
            <>
                <li className="nav-item">
                    {['init', 'pic'].includes(performState) &&
                        <Button onClick={() => this.handleStartWorkout()}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPlay}/>
                        </Button>
                    }
                    {['precursor', 'warmup', 'cooldown'].includes(performState) &&
                        nonSyncedPlayerIsPlaying &&
                        <Button onClick={() => this.setState({nonSyncedPlayerIsPlaying: false})}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPause}/>
                        </Button>
                    }
                    {['precursor', 'warmup', 'cooldown'].includes(performState) &&
                        !nonSyncedPlayerIsPlaying &&
                        <Button onClick={() => this.setState({nonSyncedPlayerIsPlaying: true})}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPlay}/>
                        </Button>
                    }
                    {(performState === 'cue_perform') &&
                        <Button onClick={() => this.handleStartMain()}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPlay}/>
                        </Button>
                    }
                    {performState === 'performing' &&
                        <Button onClick={() => this.handlePauseWorkout()}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPause}/>
                        </Button>
                    }
                    {performState === 'paused' &&
                        <Button onClick={() => this.handleResumeWorkout()}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faPlay}/>
                        </Button>
                    }
                </li>
                <li className="nav-item">
                    {!['cue_perform', 'done'].includes(performState) &&
                        <Button onClick={() => this.handleNextButton()}
                                variant="sm"
                                className="ctw-perform-button">
                            <FontAwesomeIcon icon={faStepForward}/>
                        </Button>
                    }
                </li>
            </>
        )
            ;
    };

    /*
        <Button onClick={() => this.handleToggleFilmstrip()} className={`btn-info ${classes}`}>
            {filmstripLabel}
        </Button>
    */

    // ToDo: how to position marker with React components - css based on state
    // Rely on jQuery for now?
    //

    renderFilmstrip = (elapsedSecs) => {
        const {currentExercise, exercises, roundState} = this.state;
        const {restDuration, workout, workoutPerform} = this.props;

        const scrubberValue = workout.total_secs ? ((elapsedSecs / workout.total_secs) * 100.0) : 0;

        return (
            <>
                <div className="scrolling-wrapper-flexbox">
                    {
                        exercises.map((exercise, index) => {
                                const stageClassName = (index === currentExercise) && (roundState === 'round') ?
                                    'card card-4 selected' : 'card card-4';
                                const restClassName = (index === (currentExercise - 1)) && (roundState === 'rest') ?
                                    'card card-1 rest selected' : 'card card-1 rest';

                                return (
                                    <>
                                        <div key={`we-${index * 2}`}>
                                            <div className={stageClassName}>
                                                <h2>
                                                    <ExerciseThumb exercise={exercise} {...this.props}/>
                                                </h2>
                                            </div>
                                        </div>
                                        {workoutPerform.timed && (index < exercises.length - 1) && (restDuration > 0) &&
                                            <div key={`we-${(index * 2) + 1}`}>
                                                <div className={restClassName}>
                                                <span className="align-middle">
                                                    Rest
                                                </span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                );
                            }
                        )
                    }
                </div>
                <div className="scrubber-container">
                    <div className="w3-light-grey">
                        <div className="w3-blue" style={{width: scrubberValue}}></div>
                    </div>
                </div>
            </>
        );
    };

    startTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }

        this.timer = setInterval(() =>
            this.secondHandler(), 1000
        );
    };

    stopTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    };

    renderExternalMediaPlayer = () => {
        const {layout, performState} = this.state
        const {workout} = this.props;
        let classes = `${layout}-mediaplayer`;
        const mediaLink = workout?.external_media?.[0]?.link;

        if (!mediaLink) return null;

        const player =
            <SwsVideoPlayer
                classes={classes}
                controls={true}
                forwardRef={this.swsMediaPlayerRef}
                playerOnEnded={() => {
                    Config.debug('external media completed');
                    // Restart media?
                }}
                playerShouldBePlaying={performState === 'loading_perform' || performState === 'performing'}
                playerShouldBeMuted={['performing', 'paused'].includes(performState) ? false : true}
                url={mediaLink}
            />;

        return (
            <>
                <div className={classes}>
                    {player}
                </div>
            </>
        );
    };

    renderSyncedVideoPlayer = () => {
        const {currentExercise, performState, syncedPlayerIsFullFrame} = this.state
        const {workout} = this.props;
        let classes = syncedPlayerIsFullFrame ? 'full-frame-player' : 'corner-player';
        if (this.isSingleVideoWorkout()) {
            classes = 'player-wrapper';
        }

        if (!workout || !workout.main_link) return null;

        if (currentExercise?.clip) return null

        return (
            <div className={classes}>
                <SwsVideoPlayer
                    classes="react-player"
                    controls={true}
                    forwardRef={this.swsVideoPlayerRef}
                    playerOnBufferEnd={this.playerOnBufferEnd}
                    playerOnDuration={(duration) => {
                        this.playerOnDuration(duration)
                    }}
                    playerOnEnded={this.gotoNextPerformState}
                    playerOnPause={() => {
                        if (performState !== 'cue_perform') this.handlePauseWorkout()
                    }}
                    playerOnPlay={() => {
                        if (performState === 'loading_perform') {
                            this.setState({performState: 'cue_perform'});
                        } else {
                            this.handleMainPlaying();
                        }
                    }}
                    playerOnProgress={this.playerOnProgress}
                    playerOnReady={this.playerOnReady}
                    playerShouldBePlaying={performState === 'loading_perform' || performState === 'performing'}
                    playerShouldBeMuted={['performing', 'paused'].includes(performState) ? false : true}
                    url={workout.main_link}
                />
            </div>
        );
    };

    renderVideoPlayer = (url) => {
        const {nonSyncedPlayerIsPlaying, performState} = this.state;

        return (
            <div className={'player-wrapper'}>
                <SwsVideoPlayer
                    classes="react-player"
                    controls={true}
                    url={url}
                    playerOnEnded={this.gotoNextPerformState}
                    playerOnDuration={(duration) => {
                        this.playerOnDuration(duration)
                    }}
                    playerOnPlay={() => {
                        this.startTimer();
                        this.setState({nonSyncedPlayerIsPlaying: true})
                    }}
                    playerOnPause={() => {
                        this.stopTimer();
                        this.setState({nonSyncedPlayerIsPlaying: false})
                    }}
                    playerOnReady={this.playerOnReady}
                    playerShouldBePlaying={nonSyncedPlayerIsPlaying}
                    playerShouldBeMuted={['performing', 'paused'].includes(performState) ? false : true}
                />
            </div>
        )
    };

    secondHandler = () => {
        const {increment, workout, workoutPerform} = this.props;

        const {
            elapsedSecs,
            performState,
            restDuration,
            roundDuration,
            roundState,
            roundSecs,
            restSecs,
        } = this.state;

        let nextState = Object.assign({}, this.state)

        if (performState === 'warmup') {
            nextState.elapsedSecs = elapsedSecs + increment;

        } else if (performState === 'performing') {
            nextState.elapsedSecs = elapsedSecs + increment;

            if (workout.synced) {
                // roundState shall always be 'round'
                // Future: a stop will be defined and checked here

            } else if (workoutPerform.timed) {
                if (roundState === 'round') {
                    nextState.roundSecs = roundSecs - increment;
                    if (roundSecs <= 1) {
                        nextState = Object.assign(nextState, this.nextState());

                        if (nextState.roundState !== 'done') {
                            if (nextState.restDuration !== 0) {
                                nextState.restSecs = nextState.restDuration;
                                nextState.roundState = 'rest';
                            }
                            nextState.roundSecs = nextState.roundDuration;
                            nextState.totalRounds++;
                        }

                        if (nextState.roundState !== 'done') {
                            this.playSound('start');
                        }
                    } else if (roundSecs < this.COUNTDOWN) {
                        this.playSound('countdown');
                    } else if (workoutPerform.playMidpoint && (roundSecs < ((roundDuration / 2) + 1))) {
                        this.playSound('midpoint');
                    }
                } else if (roundState === 'rest') {
                    nextState.restSecs = restSecs - increment;
                    if (restSecs <= 1) {
                        nextState.roundSecs = roundDuration;
                        nextState.restSecs = restDuration;
                        nextState.roundState = 'round';
                        this.playSound('start');
                    } else if (restSecs < this.COUNTDOWN) {
                        this.playSound('countdown');
                    }
                }
            }
        }

        this.setState(nextState);
    };

    // !! if a workout contains a workout group, then it's durations won't be able to
    // be overridden by a user.

    nextState = () => {
        const {exercises, workout, workoutPerform} = this.props;
        const {
            currentExercise, currentNote, exerciseIndex, performState, restDuration, roundDuration,
            roundState, segmentIndex, sets, setIndex
        } = this.state;

        let newCurrentExercise = currentExercise;
        let newCurrentNote = currentNote;
        let newPerformState = performState;
        let newRestDuration = restDuration;
        let newRoundDuration = roundDuration;
        let newRoundState = roundState;
        let newExerciseIndex = exerciseIndex;
        let newSegmentIndex = segmentIndex;
        let newSetIndex = setIndex;
        let newSets = sets;

        const inGroupSet = exercises[exerciseIndex].is_group;

        // Next Segment in Group Exercise
        if (inGroupSet && ((segmentIndex + 1) < exercises[exerciseIndex].segments.length)) {
            newSegmentIndex++;
            newCurrentExercise = exercises[exerciseIndex].segments[newSegmentIndex];
            newCurrentNote = workoutPerform?.exercises?.[exerciseIndex]?.exercises?.[newSegmentIndex]?.note || '';

            newRoundDuration = PerformUtils.calcDuration('round', workoutPerform, exerciseIndex, newSegmentIndex);
            newRestDuration = PerformUtils.calcDuration('rest', workoutPerform, exerciseIndex, newSegmentIndex);

            // Next set for Exercise
        } else if ((setIndex + 1) < sets) {
            newSetIndex = setIndex + 1;

            if (inGroupSet) {
                newSegmentIndex = 0;
                newCurrentExercise = exercises[exerciseIndex].segments[newSegmentIndex];
                newCurrentNote = workoutPerform.exercises[exerciseIndex].note || '';

                newRoundDuration = PerformUtils.calcDuration('round', workoutPerform, exerciseIndex, newSegmentIndex);
                newRestDuration = PerformUtils.calcDuration('rest', workoutPerform, exerciseIndex, newSegmentIndex);
            }

            // Next Exercise
        } else if ((exerciseIndex + 1) < exercises.length) {
            newExerciseIndex = exerciseIndex + 1;
            newSetIndex = 0;
            newSegmentIndex = 0;
            newCurrentExercise = exercises[newExerciseIndex];
            newRoundState = roundState === 'rest' ? 'round' : roundState;
            newCurrentNote = workoutPerform.exercises[newExerciseIndex].note || {};

            if (newCurrentExercise.is_group) {
                newCurrentExercise = exercises[newExerciseIndex].segments[newSegmentIndex];

                newRoundDuration = PerformUtils.calcDuration('round', workoutPerform, newExerciseIndex, newSegmentIndex);
                newRestDuration = PerformUtils.calcDuration('rest', workoutPerform, newExerciseIndex, newSegmentIndex);

            } else {
                newRoundDuration = PerformUtils.calcDuration('round', workoutPerform, newExerciseIndex);
                newRestDuration = PerformUtils.calcDuration('rest', workoutPerform, newExerciseIndex);
            }

            // No override of sets at the segment level
            newSets = PerformUtils.calcDuration('sets', workoutPerform, newExerciseIndex);
        } else {
            newRoundState = 'done';
            newPerformState = (workout.cooldown_link) ? 'cooldown' : 'done';
            this.playSound('done');
            this.stopTimer();
        }

        return {
            currentExercise: newCurrentExercise,
            currentNote: newCurrentNote,
            exerciseIndex: newExerciseIndex,
            performState: newPerformState,
            restDuration: newRestDuration,
            roundDuration: newRoundDuration,
            roundState: newRoundState,
            segmentIndex: newSegmentIndex,
            sets: newSets,
            setIndex: newSetIndex,
        };
    };
}
