import { Col, Form, Row } from "react-bootstrap";
import React from "react";

const PublicToggle = (props) => {
    const { handleTogglePublic, isPublic, mode } = props;

    return (
        <Form.Group>
            <Row className="ps-2">
                <Col xs={6}>
                    <div className="custom-control custom-switch">
                        {['new', 'edit'].includes(mode) &&
                            <Form.Check
                                checked={isPublic}
                                readOnly={mode === 'show'}
                                type="checkbox"
                                label="Publicly searchable"
                                id="customSwitchPublic"
                                onClick={handleTogglePublic}
                            />
                        }
                        {mode === 'show' &&
                            <h6>{!isPublic && (<>Not </>)}Publicly searchable</h6>
                        }
                    </div>
                </Col>
            </Row>
        </Form.Group>
    )
};

export default PublicToggle;
