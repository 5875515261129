import React, {useContext, useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import {Row, Form, Col} from 'react-bootstrap';
import PerformControls from "../perform_controls";
import PerformUtils from "../../utils/perform_utils";
import "react-alice-carousel/lib/alice-carousel.css";
import ExerciseClipMedia from "../exercises/exercise_clip_media";
import ExerciseAnimatedImage from "../exercises/exercise_animated_image";
import {UserContext} from "../../providers/user_provider";

// When invoked by ExerciseList (part of a workout), props.workoutPerform are manipulated and used as is.
// They may be saved later when the whole workout is saved.

// When invoked by TopList, props.workoutPerform are copied to state.localWorkoutPerform where
// they may be manipulated and saved.

export default function ExerciseModalRenderer(props) {
    const {
        category, configureType, exercise, exercisePerform, mode, saveMode,
        setExercisePerform, workout, workoutPerform
    } = props;

    const {user} = useContext(UserContext);
    const [useWorkoutDefaults, setUseWorkoutDefaults] = useState(true);
    let { groupExerciseId } = useParams();

    // Only run once so that we can toggle thereafter without the inner component having to try to keep changing
    // exercisePerform, AND reacting to it in the re-determination of useWorkoutDefaults


    useEffect(() => {
        if (exercisePerform) {
            const hasTimingKey = Object.keys(exercisePerform).map(k => ['rest', 'round', 'sets'].includes(k)).length > 0;
            setUseWorkoutDefaults(!hasTimingKey);
        }

    }, [exercisePerform]);

    const handleToggleWorkoutDefaults = () => {
        if (useWorkoutDefaults) {
            // This should essentially disable the useWorkoutDefaults
            if (workoutPerform) {
                setExercisePerform(Object.assign({}, PerformUtils.performFromDefaults(workoutPerform)));
            } else {
                setExercisePerform(PerformUtils.systemDefaults());
            }
        } else {
            setExercisePerform({});
        }
    };

    const handleUpdateExercisePerform = (performUpdates) => {
        setExercisePerform(Object.assign({}, exercisePerform, performUpdates));
    };

    const handleUpdateNote = (newNote) => {
        let exercisePerformUpdates = Object.assign({}, exercisePerform);
        exercisePerformUpdates['note'] = newNote;

        setExercisePerform(exercisePerformUpdates);
    };

    // When mode == 'index', workoutPerform doesn't exists

    const renderTimings = (readOnly) => {
        const showWorkoutDefaults = (category === 'workouts' && mode === 'edit') ||
            (category === 'exercises' && saveMode !== 'none');
        const showRoundRest = workout?.workout_type === 'timed';
        const perform = (exercisePerform && Object.keys(exercisePerform)?.length) ? exercisePerform : workoutPerform;

        return (
            <>
                {showWorkoutDefaults &&
                    <Row>
                        <Col xs={{span: 8, offset: 2}}>
                            <Form.Group controlId="formBasicCheckbox7 text-center">
                                <Form.Check type="checkbox"
                                            className="text-dark"
                                            checked={useWorkoutDefaults}
                                            label="Use Workout Defaults"
                                            onChange={() => {
                                                handleToggleWorkoutDefaults()
                                            }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <PerformControls
                    defaults={workoutPerform}
                    perform={perform}
                    readOnly={readOnly}
                    showRoundRest={showRoundRest}
                    showSets={true}
                    updatePerform={handleUpdateExercisePerform}
                />
            </>
        );
    };

    if (exercise === null || exercise === undefined) return null;

    const readOnly = (saveMode === 'none') || useWorkoutDefaults;
    const bgClasses = exercise.standard_animation ? 'card bg-dark' : 'card bg-light'
    const showNote = !groupExerciseId && (saveMode !== 'createGroup') &&
        (!readOnly || (readOnly && exercisePerform?.note));
    const showTimings = ((category === 'workouts') || (category === 'exercises' && saveMode !== 'none')) &&
        (user.is_admin || (workout?.user_id === user.id)) && workout?.workout_type === 'timed';

    return (
        <>
            <Form.Group>
                <>
                    <div className={bgClasses} key={`ce-${exercise.id}`}>
                        {exercise?.clip && <ExerciseClipMedia {...props}
                                                              mode="show"
                                                              exercise={exercise}
                                                              clipMode="video"
                                                              playerShouldBePlaying={true}/>}
                        {!exercise?.clip && <ExerciseAnimatedImage exercise={exercise} classes="card-img" size="md"/>}
                        {(configureType === 'GroupExercise') &&
                            <div className="card-img-overlay">
                                <div className="text-center">
                                    <h3 className="card-title text-white text-shadow">
                                        <div>{exercise.title}</div>
                                    </h3>
                                </div>
                            </div>
                        }
                    </div>
                    {showTimings && renderTimings(readOnly)}
                    {showNote &&
                        <>
                            <br/>
                            <Form.Group>
                                <Form.Control required type="text" name="title" placeholder="instructions"
                                              onChange={(event) => handleUpdateNote(event.target.value)}
                                              readOnly={readOnly}
                                              value={exercisePerform?.note || ''}/>
                            </Form.Group>
                        </>
                    }
                </>
            </Form.Group>
        </>
    );
}
