import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Formatter from "../../utils/formatter";
import WorkoutIcon from "../common/workout_icon";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../providers/user_provider";

const WorkoutItem = (props) => {
    const { item, workoutPerform } = props;

    const { user } = useContext(UserContext);

    const handleWorkoutClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/workouts/${item.id}`);
    }

    let location = useLocation();
    let navigate = useNavigate();
    const star = (item.is_featured && user && user.is_admin) ? (<span className='pe-1 text-warning'><FontAwesomeIcon icon={faStar} className="fa-lg" /></span>) : null;

    return (
        <a href="#" className="list-group-item list-group-item-action workout-list-item flex-row"
            onClick={handleWorkoutClick}>
            <WorkoutIcon suffix={star} workout={item} workoutPerform={workoutPerform}/>
            <Col xs={7} sm={8} className="text-center align-self-center">
                {item.title}
            </Col>
            <Col xs={3} sm={2} className="overview-wrapper">
                <small className="text-muted">
                    <p className="text-center short-label mb-0">
                        {item.total_secs && Formatter.toHHMMSS(item.total_secs)}
                        {!item.total_secs && (item.numExercises > 0) &&
                            `${item.numExercises} EX`}
                    </p>
                    <div className="mb-1 text-center">
                        {item.username &&
                            <div className="text-primary" onClick={(event) => {
                                const queryParams = new URLSearchParams(location.search);
                                queryParams.set('user_slug', item.user_slug);
                                navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
                                event.stopPropagation();
                                return true;
                            }}>
                                <span className="align-left short-label">
                                    {item.username}
                                </span>
                            </div>
                        }
                    </div>
                </small>
            </Col>
        </a>
    );
}

export default WorkoutItem;
