import React, {useContext, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import Formatter from "../../utils/formatter";
import HeartbeatImg from "../common/heartbeat_img";
import {useNavigate} from "react-router-dom";
import {UserService} from "../../services/user_service";
import * as Yup from "yup";
import {useForm, useFormState} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import { UserContext } from '../../providers/user_provider';

export default function UserEditModal(props) {
    const {fieldName, fieldType, hide, setUser, show} = props;

    const {user} = useContext(UserContext);

    // ready, saving, saved
    const [savingState, setSavingState] = useState('ready');
    const [serverError, setServerError] = useState(null);

    let navigate = useNavigate();

    const validationSchema = Yup.object()
        .shape(UserService.fieldValidation(fieldName));

    let defaults = {};
    defaults[fieldName] = user[fieldName];

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: {errors}
    } = useForm({
        defaultValues: defaults,
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange'
    });

    const {dirtyFields} = useFormState({control});

    const handleSave = data => {
        setSavingState('saving');

        UserService.update(user.id, {user: data}, setError)
            .then(
                (data) => {
                    if (data.errors) {
                        for (const [field, error] of Object.entries(data.errors)) {
                            setError(field, {type: "manual", message: error});
                        }
                        setSavingState('ready');
                    } else if (data.user) {
                        if (fieldName === 'username') {
                            navigate(`/users/${data.user.slug}`)
                            // Force reloading of top-level user.
                            // ToDo: fix this with a UserContext
                            window.location.reload(true);
                        } else {
                            setUser(data.user);
                            setSavingState('saved');
                            hide();
                        }
                    }
                }
            )
            .catch(err => {
                setSavingState('ready');
                setServerError(err.message);
            });
        return;
    }

    return (
        <Modal animation={false} show={show} onHide={() => hide()}>
            <Form id="user-edit-modal-form" onSubmit={handleSubmit(handleSave)}>
                <Modal.Header>
                    <Modal.Title>Set {Formatter.capitalizeFirst(fieldName)}</Modal.Title>
                    <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => hide()}/>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pt-2">
                        <Col xs={12}>
                            {fieldType === 'textarea' &&
                            <input name={fieldName}
                                   type="text"
                                   autocomplete="off"
                                   as="textarea"
                                   rows="2"
                                   {...register(fieldName)}
                                   className={`form-control ${(errors[fieldName] && dirtyFields[fieldName]) ? 'is-invalid' : ''}`}
                                   defaultValue={user[fieldName]}
                            />
                            }
                            {fieldType === 'text' &&
                            <input name={fieldName}
                                   autocomplete="off"
                                   type="text"
                                   defaultValue={user[fieldName]}
                                   {...register(fieldName)}
                                   className={`form-control ${(errors[fieldName] && dirtyFields[fieldName]) ? 'is-invalid' : ''}`}
                            />
                            }
                            <div
                                className="invalid-feedback">{dirtyFields[fieldName] ? errors[fieldName]?.message : ''}</div>
                        </Col>
                    </Row>
                    <Row>
                        {savingState === 'saving' &&
                        <div className="d-flex my-0 w-100 mt-2">
                            <HeartbeatImg/>
                        </div>
                        }
                        {serverError && <div>{serverError}</div>}
                        <div
                            className="invalid-feedback">{dirtyFields[fieldName] ? errors[fieldName]?.message : ''}
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="primary" type="submit">Save</Button>
                        <Button variant="secondary" onClick={() => hide()}>Cancel</Button>
                    </>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}