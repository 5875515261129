import 'sprintf-js';

let Formatter = {

    toHHMMSS(number, options = {}) {
        let numHours = Math.floor(number / 3600),
            numMinutes = Math.floor((number - (numHours * 3600)) / 60),
            output = '',
            numSeconds = (number - (numHours * 3600) - (numMinutes * 60));
        numSeconds = (options.showDecimal ? numSeconds.toFixed(1) : Math.round(numSeconds));
        options = options || {};

        if (numHours > 0) {
            output = sprintf('%2d:%02d:%02d', numHours, numMinutes, numSeconds);
        } else if (numMinutes > 0) {
            output = sprintf('%2d:%02d', numMinutes, numSeconds);
        } else {
            output = sprintf(':%02d', numSeconds)
        }

        return output;
    },

    toMMSS(number, options = {}) {

        let minutes = Math.floor(number / 60),
            time = '',
            seconds = (number - (minutes * 60));
        seconds = (options.showDecimal ? seconds.toFixed(1) : Math.round(seconds));
        options = options || {};

        if (minutes < 10 && options.zeroPad) {
            minutes = "0" + minutes;
        }
        if (seconds < 10 && (options.zeroPad || minutes > 0)) {
            seconds = "0" + seconds;
        }

        if (typeof options.minutes === 'undefined' || options.minutes)
            time += (minutes !== "00") ? minutes : '';
        if (typeof options.seconds === 'undefined' || options.seconds)
            time += ':' + seconds;
        return time;
    },

    fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
    },

    capitalizeFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    underscoreToUri(string) {
        return string.toLowerCase()
            .split('_')
            .map((s) => s.substring(0, s.length - 1))
            .join('/');
    },

    capitalizeEachWord(string, splitChar) {
        return string.toLowerCase()
            .split(splitChar)
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    },

    categoryToLabel(category) {
        if (!category) {
            return "";
        }

        if (category === 'external_media') {
            return "Media";
        }
        return Formatter.capitalizeEachWord(category.substring(0, category.length - 1), '_');
    },

    tagToLabel(tagName) {
        return Formatter.capitalizeEachWord(tagName.split('_').join(' '));
    }
};

export default Formatter;

