import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

const ImageUploadObject = (props) => {
    const { imageObject, mode } = props;
    let showDisclaimer = false;

    let imageSource = imageObject.src ? imageObject.src : null;

    if (imageObject.tempUploadFile) {
        imageSource = imageObject.tempUploadFile;
        showDisclaimer = (mode === 'edit');
    }

    const cloudUpload = <FontAwesomeIcon icon={faCloudUploadAlt} size="4x" className="h-100" />;
    const imageIcon = <FontAwesomeIcon icon={faImage} size="4x"/>;

    return (
        <>
            {imageObject.aasm_state === 'empty' && !imageSource &&
                <div className="text-center w-100 upload-image">
                    {imageIcon}
                    <h6>Workout Image</h6>
                </div>
            }
            {imageObject.aasm_state === 'empty' && imageSource &&
                <img className="animated-image" style={{}} alt="animated exercise" src={imageSource} />
            }
            {imageObject.aasm_state === 'uploading' && imageSource &&
                <div className="fadeInAndOut text-center w-100 upload-image">
                    {cloudUpload}
                </div>
            }
            {['conversion_in_progress', 'complete'].includes(imageObject.aasm_state) && imageSource &&
                <div className="d-flex justify-content-center bg-dark">
                    <img className="image-preview" alt="animated exercise" src={imageSource} />
                </div>
            }
            {showDisclaimer &&
                <div>If image is upside-down, it will be fixed upon conversion</div>
            }
        </>
    )
};

export default ImageUploadObject;
