import React, { useContext, useDebugValue, useEffect, useRef, useState } from 'react';
import NavEditButtons from "../nav/nav_edit_buttons";
import { Col, Form, InputGroup, Navbar, Row } from 'react-bootstrap';
import FormDataUtils from "../../utils/form_data_utils";
import API from "../../utils/api";
import { NavContext } from "../../providers/nav_provider";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { ConfigContext } from "../../utils/config_context";
import ApiLoadingWrapper from '../common/api_loading_wrapper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwsVideoPlayer from "../players/sws_video_player";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from 'swr';
import { SwrUtils } from '../../utils/swr_utils';
import ObjectDefaults from '../../utils/object_defaults';
import { UserContext } from "../../providers/user_provider";
import ShowNavButtons from '../nav/show_nav_buttons';
import parse from 'html-react-parser';
import * as Sentry from "@sentry/browser";
import useStateWithLabel from '../../utils/use_state_with_label';

// Basic Modal Operation:
//
// Overview :
//     Use opengraphData.url to load the player
//     When loading the modal from a list, opengraphData is copied from media
//     When creating a new entry, opengraphData = response from POST /api/opengraph_data
//


export default function ExternalMedia(props) {
    const { mode } = props;

    const config = useContext(ConfigContext);
    const { dirty, setAfterLoginUrl, setDirty, setAlert } = useContext(NavContext);
    const { user } = useContext(UserContext);
    const mediaForm = useRef(null);
    let navigate = useNavigate();
    let { externalMediaId } = useParams();

    const [externalMedia, setExternalMedia] = useState(ObjectDefaults.externalMedia());
    const [audioOnly, setAudioOnly] = useStateWithLabel(false, 'audioOnly');
    const [mediaAlias, setMediaAlias] = useState("");
    const [debugYouTube, setDebugYouTube] = useState(false);

    // opengraphUrl => opengraphData => (opengraphError, isSaving) => close

    const [isSaving, setIsSaving] = useState(false);
    const [opengraphUrl, setOpengraphUrl] = useState(null);
    const [opengraphData, setOpengraphData] = useStateWithLabel(null, 'opengraphData');
    const [opengraphError, setOpengraphError] = useState(null);

    // null, parsing, loading, loaded, error
    // Sets to Loaded if media.url is passed in
    const [opengraphLoadingMode, setOpengraphLoadingMode] = useState(null);

    const userCanEdit = user.is_admin || (externalMedia.username === user.username);
    let showEditButton = user.id && userCanEdit;

    const {
        error,
        mutate,
        isLoading
    } = useSWR((config.apiBase && externalMediaId) ? `${config.apiBase}/external_media/${externalMediaId}` : null,
        SwrUtils.authFetcher, {
        ...SwrUtils.stdOptions, onSuccess: (data) => {
            setExternalMedia(data);
        }
    });

    useEffect(() => {
        setOpengraphData(externalMedia?.og_data || null);
        setOpengraphUrl(externalMedia?.link || null);
        setAudioOnly(externalMedia?.audio_only || null);
        setMediaAlias(externalMedia?.alias || null);
    }, [externalMedia])

    useEffect(() => {
        if (mode === 'new' && !user.id) {
            setAfterLoginUrl('/external_media/new');
            setAlert({
                autoClose: 3000,
                title: null,
                message: 'Please register or login to create a new media item',
                show: true
            });
            navigate("/register");
            return;
        }
    }, [mode, navigate, setAfterLoginUrl, setAlert, user.id])

    useEffect(() => {
        if (opengraphData?.id) {
            if (opengraphData?.youtube_video) {
                if (opengraphData.youtube_video.embeddable === false) {
                    setOpengraphError('Not playable outside YouTube')
                    setOpengraphLoadingMode('error');
                    return;
                }
            }
            setOpengraphLoadingMode('loaded');
        }
    }, [opengraphData]);

    useEffect(() => {
        if (opengraphLoadingMode === 'loading') {

            const og_data = {
                og_url: opengraphUrl
            }

            API.post(`${config.apiBase}/opengraph`, JSON.stringify(og_data))
                .then((data) => {
                    setOpengraphData(data);
                }).catch(error => {
                    // ToDo: expand this to include a retry
                    if (typeof error === 'string') {
                        setOpengraphError(error);
                    } else {
                        setOpengraphError('Opengraph error');
                    }
                    setOpengraphLoadingMode('error');
                });
        }
    }, [config, opengraphLoadingMode, opengraphUrl, setOpengraphData])

    useEffect(() => {
        if (opengraphLoadingMode === 'parsing') {
            if (opengraphUrl.match(/^<iframe/)) {
                const captureRegex = /src="(?<link>[^"]*)"/;
                let link = opengraphUrl.match(captureRegex)?.groups?.link;
                if (link) {
                    link = link.replace(/&amp;/g, '&');
                    setOpengraphUrl(link);
                    setOpengraphLoadingMode('loading');
                } else {
                    setOpengraphError("Invalid embed code");
                    setOpengraphLoadingMode('error');
                }
            } else if (opengraphUrl.match(/youtube\.com\/shorts/)) {
                // https://youtube.com/shorts/HQWAXzeWIRg?si=WtgJCC8zvTAffHCR
                const captureRegex = /shorts\/(?<id>[^\/]*)/;
                let id = opengraphUrl.match(captureRegex)?.groups?.id;
                if (id) {
                    setOpengraphData({ video_url: `https://youtu.be/${id}` });
                    setOpengraphLoadingMode('loading');
                } else {
                    setOpengraphError("Unusable link");
                    setOpengraphLoadingMode('error');
                }
            } else if (opengraphUrl.match(/youtube\.com\/clip/)) {
                setOpengraphError("To use a YouTube clip, you must paste the embed code - starting with <iframe...");
                setOpengraphLoadingMode('error');
            } else {
                setOpengraphLoadingMode('loading');
            }

        }
    }, [opengraphLoadingMode, opengraphUrl, setOpengraphData, setOpengraphLoadingMode, setOpengraphUrl])

    const handleAliasChange = (event) => {
        setDirty(true);
        setMediaAlias(event.target.value)
    }

    const handleToggleDebugYouTube = () => {
        setDebugYouTube(!debugYouTube);
    }

    const handleUrlChange = (event) => {
        event.preventDefault();
        setDirty(true);
        setOpengraphError(null);
        setOpengraphUrl(event.target.value);
        if (event.target.value.length > 0) {
            setOpengraphLoadingMode('parsing');
        } else {
            setOpengraphLoadingMode(null);
        }
    };

    const handleOnEnded = (err) => {
    }

    const handleOnError = (err) => {
    }

    const handleOnProgress = () => {
    }

    const handleOnPlay = () => {
    }

    const handleReload = () => {
        if (externalMediaId) {
            mutate(`${config.apiBase}/external_media/${externalMediaId}`);
        } else {
            setExternalMedia(null);
        }
    }

    const handleSubmit = (event) => {
        if (isSaving) return;
        if (!opengraphData?.id) return;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const form = mediaForm.current;

        const formMediaData = new FormData(form);

        let formObject = Object.assign(FormDataUtils.formDataToObject(formMediaData),
            {
                og_id: opengraphData.id,
                audio_only: audioOnly ? '1' : '0'
            });

        setIsSaving(true);
        const mediaJson = JSON.stringify(formObject);

        if (externalMedia?.id) {
            API.put(`${config.apiBase}/external_media/${externalMedia.id}`, mediaJson)
                .then(result => {
                    setIsSaving(false);
                    setDirty(false);
                    navigate(-1);
                    mutate(`${config.apiBase}/external_media/${externalMedia.id}`);
                }).catch(error => {
                    alert(error.message);
                });
        } else {
            API.post(`${config.apiBase}/external_media`, mediaJson)
                .then(result => {
                    setIsSaving(false);
                    setDirty(false);
                    navigate(-1);
                })
                .catch(error => {
                    alert(error.message);
                });
        }
    };

    const handleAliasFormCleared = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setMediaAlias("");
    };

    const handleLinkFormCleared = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpengraphUrl(null);
        setOpengraphData(null);
        setOpengraphError(null);
        setOpengraphLoadingMode(null);
    };

    const renderLinkFormInputPrepend = () => {
        return (
            <>
                {(opengraphLoadingMode === null) &&
                    <InputGroup.Text id="basic-addon">Paste</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'error') &&
                    <InputGroup.Text className="text-danger" id="basic-addon">Error</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'loading') &&
                    <InputGroup.Text className="text-warning" id="basic-addon">Loading</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'loaded') &&
                    <InputGroup.Text className="text-secondary" id="basic-addon">Loaded</InputGroup.Text>
                }
            </>
        )
    }

    const renderLinkFormInputAppend = () => {
        return (
            <>
                {['loaded', 'error'].includes(opengraphLoadingMode) &&
                    renderLinkFormClearButton(handleLinkFormCleared)}
            </>
        )
    }

    const renderLinkFormClearButton = (handleClick) => {
        return (
            <InputGroup.Text id="clear-addon" onClick={handleClick}>
                <FontAwesomeIcon icon={faCircleXmark} />
            </InputGroup.Text>
        )
    }
    const renderAudioOnlyField = () => {
        if (!opengraphData?.id) return;

        if (mode === 'show' && !audioOnly) return null;

        return (
            <Row>
                <Col xs={12}>
                    {mode === 'show' && audioOnly &&
                        <h5>Audio Only</h5>
                    }
                    {mode !== 'show' &&
                        <InputGroup>
                            <Form.Check
                                defaultChecked={audioOnly}
                                type="checkbox"
                                label="Audio Only"
                                name="audio_only"
                                onClick={() => {
                                    setAudioOnly(!audioOnly);
                                }}
                            />
                        </InputGroup>
                    }
                </Col>
            </Row>
        )
    }

    const renderForm = () => {
        return (
            <Form id="external-media-modal-form" noValidate ref={mediaForm} className="w-100 pt-2"
                onSubmit={handleSubmit}>
                <Form.Group className="w-100">
                    <Row>
                        <Col xs={1} className="ps-2">
                            <Form.Label>
                                <div className="text-center" style={{ zIndex: "1", position: "relative", fontSize: '1.6rem' }}>
                                    <FontAwesomeIcon icon={faYoutube} color="red" size="2x" className="fa-lg" />
                                </div>
                            </Form.Label>
                        </Col>
                        <Col xs={11}>
                            <InputGroup>
                                {renderLinkFormInputPrepend()}
                                <Form.Control required
                                    type="text"
                                    name="link"
                                    readOnly={mode === 'show'}
                                    placeholder="YouTube Link or Embed"
                                    onChange={handleUrlChange}
                                    value={opengraphUrl || ''} />
                                {['new', 'edit'].includes(mode) && renderLinkFormInputAppend()}
                            </InputGroup>
                            {opengraphError &&
                                <Form.Text className="d-flex justify-content-center text-muted">
                                    {opengraphError}
                                </Form.Text>
                            }
                        </Col>
                        {false && config.youTubeDebug &&
                            <Form.Group controlId="formBasicCheckboxYouTubeDebug">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" checked={debugYouTube}
                                        onChange={handleToggleDebugYouTube}
                                        className="custom-control-input"
                                        id="customSwitchYouTube" />
                                    <label className="custom-control-label" htmlFor="customSwitchYouTube">
                                        Debug You Tube Link
                                    </label>
                                </div>
                            </Form.Group>
                        }
                    </Row>
                    {!opengraphError && renderMediaTitleField()}
                    {!opengraphError && renderMediaAliasField()}
                    {!opengraphError && renderAudioOnlyField()}
                </Form.Group>
            </Form>
        )
    };

    const renderMediaAliasField = () => {
        if (!opengraphData?.id) return null;

        if ((mode !== 'new') && !externalMedia) return null;

        let mediaAliasComponent = <div className='pt-1'>{mediaAlias}</div>

        if (['new', 'edit'].includes(mode)) {
            mediaAliasComponent = (
                <InputGroup>
                    <Form.Control required
                        type="text"
                        name="alias"
                        onChange={handleAliasChange}
                        placeholder="Title Alias"
                        value={mediaAlias} />
                    {(mediaAlias?.length > 0) && renderLinkFormClearButton(handleAliasFormCleared)}
                    <Form.Control.Feedback type="invalid">
                        Set an alias for the media
                    </Form.Control.Feedback>
                </InputGroup>
            );
        } else if (!mediaAlias) {
            return null;
        }

        return (
            <Row>
                <Col xs={2} className="pt-1">
                    <Form.Label>
                        <h5>
                            Alias
                        </h5>
                    </Form.Label>
                </Col>
                <Col xs={10}>{mediaAliasComponent}</Col>
            </Row>
        )
    }

    // Question: how should we handle duplicates?
    // Should the same video be able to be saved with different titles?
    // If so, should we enforce uniqueness to the titles?
    // Maybe we should just have the title source of truth be from YouTube
    //  - as we wouldn't want to show multiple titles.

    const renderMediaTitleField = () => {

        if (!opengraphData?.title) return null;

        return (
            <Row className="pt-2">
                <Col xs={2}>
                    <Form.Label>
                        <h5>
                            Title
                        </h5>
                    </Form.Label>
                </Col>
                <Col xs={10}>
                    {opengraphData.title}
                </Col>
            </Row>
        )
    }

    const renderShowButtons = () => {
        return (
            <ShowNavButtons {...props}
                showEditButton={showEditButton}
                subject={externalMedia}/>
        )
    } 

    if (error) {
        const message = (error instanceof RecordNotFoundError) ? "No such media" : "An error has occurred.  Please reload the page."

        return (<div className="top-component">
            {message}
        </div>)
    }

    if (isLoading) {
        return (<div className="top-component">
            <ApiLoadingWrapper />
        </div>)
    }

    return (
        <>
            <div className="top-component">
                <Row className="px-2">
                    <Col lg={12}>
                        {opengraphData?.video_url &&
                            <div className="clip-player">
                                <SwsVideoPlayer
                                    {...props}
                                    controls={true}
                                    playerOnError={handleOnError}
                                    playerOnEnded={handleOnEnded}
                                    playerOnPlay={handleOnPlay}
                                    playerOnProgress={handleOnProgress}
                                    playerShouldBePlaying={true}
                                    playerShouldBeMuted={false}
                                    url={opengraphData?.video_url}
                                />
                            </div>
                        }
                        {mode === 'new' && !opengraphData?.video_url &&
                            <h5>Paste a YouTube Link below to be able to add it to a workout</h5>
                        }
                        {renderForm()}
                    </Col>
                </Row>
            </div>

            <Navbar fixed="bottom" bg="dark" className="justify-content-between">
                <NavEditButtons
                    {...props}
                    dirty={dirty}
                    showButtons={renderShowButtons}
                    showEditButton={showEditButton}
                    reload={handleReload}
                    resourceName="Media"
                    resourceId={externalMediaId}
                    resourceUrlStub="external_media"
                    handleSave={handleSubmit}
                />
            </Navbar>
        </>
    );
}