import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList, faEdit, faLink, faPlusCircle, faStepForward, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {Button} from "react-bootstrap";
import {Config} from './config';

let Information = {

    exerciseListAdd() {
        let addIcon = <span style={{fontSize: '1.25rem', color: '#28a745'}}>
            <FontAwesomeIcon icon={faPlusCircle}/></span>;

        return (
            <p>
                Select the {addIcon} to add another repeat of that exercise within the workout.
            </p>
        );
    },
    exerciseListHeader() {
        const edit = <span style={{fontSize: '1.25rem'}}><FontAwesomeIcon
            icon={faEdit}/></span>;

        return (
            <p>
                In this section, the {edit} will enable changing the order of the exercises.
            </p>
        );
    },
    exerciseNew() {
        const isClipsEnabled = Config.get().isClipsEnabled;

        return (
            <div>
                <h5><b>For Uploaded Exercises:</b></h5>
                <p>
                    First, create a single rep of the exercise on your device. It should be no
                    more than a few seconds in length. Upload it in the Exercise Video form
                    field which follows.
                </p>
                <p>
                    Add the exercise title, description and tags to make the exercise searchable.
                    An optional link can direct the user to more information (like a YouTube video description).
                </p>
                {isClipsEnabled &&
                <>
                    <h5><b>For YouTube Clips:</b></h5>
                    {this.youTubeClipInfo()}
                </>
                }
            </div>
        );
    },
    youTubeClipInfo() {
        return (
            <p>
                With the <a href="https://support.google.com/youtube/answer/10332730?hl=en" target="_blank"
                            rel="noreferrer">YouTube link</a>
                &nbsp;feature, you may build a workout out of virtually any content on YouTube. 
            </p>
        );
    },
    exerciseMediaClip() {
        const clipText = Config.get().isClipsEnabled ? this.youTubeClipInfo() : '';

        return (
            <div>
                <p>
                    Upload an Exercise Image or Video from your device. It should be less
                    than {`${Config.get().maxFileSizes.exerciseImageMb}`} MB.
                </p>
                <p>If it's a video, it should no more than a few seconds in length. Try to get
                    the beginning and endpoints to line up. After it uploads, it will loop
                    automatically for you to see how it will look after saving.
                </p>
                {clipText}
            </div>
        );
    },
    performDefaults() {
        const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black"/>;

        return (
            <div className="pt-2">
                <p>
                    <b>Bells</b> plays boxing-style bells to start each round of the timed {stopwatchIcon} workout.
                </p>
            </div>
        );
    },
    syncedExercises() {
        let linkIcon = <span style={{fontSize: '1rem'}}><FontAwesomeIcon icon={faLink}/></span>;

        return (
            <div className="pt-2">
                {this.exerciseListHeader()}
                <p>
                    Use the
                    <Button variant='success' className="btn-xs px-1 mx-1">
                        {linkIcon}
                    </Button>
                    button to set the start time of the exercise to the current time
                    of the player.
                </p>
                {this.exerciseListAdd()}
            </div>
        );
    },
    timedExercises() {
        return (
            <div className="pt-2">
                {this.exerciseListHeader()}
                <p>

                    Click on an Exercise in the list to override the default workout settings
                    (round, rest and sets). You can also enter additional instructions for the
                    exercise which are displayed while performing the workout.
                </p>
                {this.exerciseListAdd()}
            </div>
        );
    },
    workoutExercises() {
        return (
            <div className="pt-2">
                <p>
                    Click on an Exercise in the list in order to set any additional
                    instructions for the exercise. They will be displayed while performing
                    the workout.
                </p>
            </div>
        );
    },
    workoutLink() {
        return (
            <div className="pt-2">
                <p>
                    Enter a workout link if you want to back-link your workout to another site - like your
                    website, blog, Facebook page, etc.
                </p>
                <p>
                    The <b>Link Text</b> will be the displayed instead of the raw URL.
                </p>
            </div>
        );
    },
    workoutTypes() {
        const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#666"/>;
        const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black"/>;
        //const ytIcon = <FontAwesomeIcon icon={faYoutube} color="red"/>;
        //const linkedIcon = <FontAwesomeIcon icon={faLink}/>;

        return (
            <div className="pt-2">
                <p>
                    In a {stopwatchIcon} timed workout, a timer is used to control when exercises are
                    completed.
                </p>
                <p>
                    In a {clipboardIcon} manual workout, exercises are completed when the next one
                    is advanced to with the <FontAwesomeIcon icon={faStepForward}/> button.
                </p>
            </div>
        );
    },
};

export default Information;
