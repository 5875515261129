import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { ConfigContext } from "../../utils/config_context";
import AliceCarousel from "react-alice-carousel";
import ExerciseAnimatedImage from "./exercise_animated_image";
import ExerciseClipMedia from "./exercise_clip_media";
import PlayPauseIcon from "../common/play_pause_icon";
import NextIcon from "../common/next_icon";
import PrevIcon from "../common/prev_icon";

export default function ExerciseGroupImageCarousel(props) {
    const {
        active, customizeButton, exercise,
        groupSliderActiveIndex, handleImageClick,
        isGiphyView, mode, saveMode, setGroupSliderActiveIndex, showControls
    } = props;

    const config = useContext(ConfigContext);
    const [autoPlay, setAutoPlay] = useState(false);

    // ToDo: fix this by replaceing react-active-carousel with a better carousel like react-slick
    const myShowControls = false;

    // This is a proxy for whether the carousel is in autoplay mode or not - though this is controlled through
    // this timer, NOT the autoPlay capability of the carousel, which doesn't work so well when:
    //     autoPlay ->
    //        parentContainer computes segment sets, round, rest values
    //     onSlideChanged is used as feedback to the currentItem being shown
    //     Next/Prev is pressed, autoPlay is disabled.
    const [groupSliderTimerId, setGroupSliderTimerId] = useState(null);

    let groupSliderRef = useRef(null);

    useEffect(() => {
        if (!exercise || !active) return;

        if ((mode === 'edit') || (saveMode === 'edit')) {
            setAutoPlay(false);
        } else {
            setAutoPlay(true);
        }
        return () => {
            setAutoPlay(false);
        }
    }, [active, exercise, mode, saveMode, setAutoPlay]);

    // This just sets the timerId
    useEffect(() => {
        if (!active) return;

        if (autoPlay) {
            if (!groupSliderTimerId) {
                const interval = setInterval(() => {
                    setGroupSliderActiveIndex((groupSliderActiveIndex) => ++groupSliderActiveIndex);
                }, 2000);
                setGroupSliderTimerId(interval);
            }
        } else {
            if (groupSliderTimerId) {
                clearInterval(groupSliderTimerId);
                setGroupSliderTimerId(null);
            }
        }
        return () => {
            if (groupSliderTimerId) {
                clearInterval(groupSliderTimerId);
                setGroupSliderTimerId(null);
                setGroupSliderActiveIndex(0);
            }
        }
    }, [active, autoPlay, groupSliderTimerId, setGroupSliderActiveIndex, setGroupSliderTimerId]);


    if (!exercise.is_group) return null;

    let items = [];

    const groupExerciseClickNext = () => {
        setAutoPlay(false);
        setGroupSliderActiveIndex(groupSliderActiveIndex => groupSliderActiveIndex + 1);
    }

    const groupExerciseClickPrev = () => {
        setAutoPlay(false);
        setGroupSliderActiveIndex(groupSliderActiveIndex => groupExerciseClickNext - 1);
    }

    const handlePlayPauseButton = () => {
        setAutoPlay((autoPlay) => !autoPlay);
    }

    const renderCarouselCenterControls = () => {
        if (saveMode === 'none') {
            return (
                <div>
                    <PlayPauseIcon classes="px-4 pt-1" isPlaying={groupSliderTimerId}
                        onClick={handlePlayPauseButton} />
                </div>
            );
        } else {
            return (
                <Row className="w-100 justify-content-center">
                    <div className="d-flex justify-content-center">
                        {customizeButton}
                    </div>
                </Row>
            );
        }
    }

    if (exercise.segments) {
        items = exercise.segments.map((e, i) => {
            if (e.clip?.id && config.isClipsEnabled) {
                return (
                    <ExerciseClipMedia {...props}
                        clipMode={showControls ? 'video' : 'image'}
                        classes="w-100 img-responsive"
                        exercise={e}
                        mode='show'
                        playerShouldBePlaying={false}
                    />
                );

            } else if (isGiphyView) {
                return (
                    <ExerciseAnimatedImage
                        classes="card-img"
                        exercise={e}
                        onClick={(event) => {
                            handleImageClick(event)
                        }}
                    />
                );
            } else {
                return (
                    <img className="w-100 img-responsive card-img"
                        alt="exercise"
                        onClick={(event) => {
                            handleImageClick(event)
                        }}
                        src={`${config.s3Basepath}/${e.thumb_image}`} />
                );
            }
        });
    }
    // 
    // "modalMode" != edit: 

    // "modalMode" = edit, do not auto-scroll, respond to arrow buttons and slide indicators
    // onSlideChanged will trigger higher-order-component to update the computed round and rest values

    if (myShowControls) {
        const buttonClasses = "d-flex " + ((saveMode === 'none') ? 'justify-content-center' : 'justify-content-between');

        return (
            <Form.Group>
                <AliceCarousel mouseTracking
                    ref={groupSliderRef}
                    activeIndex={groupSliderActiveIndex}
                    autoPlay={autoPlay}
                    autoPlayControls={false}
                    autoPlayStrategy='none'
                    disableDotsControls={true}
                    disableButtonsControls={true}
                    items={items}
                >
                </AliceCarousel>
                {(items.length > 1) &&
                    <div className={buttonClasses}>
                        <PrevIcon onClick={groupExerciseClickPrev} />
                        {renderCarouselCenterControls()}
                        <NextIcon onClick={groupExerciseClickNext} />
                    </div>
                }
            </Form.Group>
        );

    } else {
        return (
            <AliceCarousel mouseTracking
                animationEasingFunction={null}
                animationType="fadeout"
                autoPlay={true}
                autoPlayInterval={2000}
                disableDotsControls={true}
                disableButtonsControls={true}
                disableSlideInfo={true}
                infinite={items.length > 1}
                items={items}>
            </AliceCarousel>
        );
    }
}