import React, {useContext, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {Col} from 'react-bootstrap';
import API from "../../utils/api";
import {SwsAnalytics} from "../../services/sws_analytics";
import {ConfigContext} from "../../utils/config_context";
import ExerciseCard from "./exercise_card";
import ExerciseListItemRowRenderer from "./exercise_list_item_row_renderer";
import {UserContext} from "../../providers/user_provider";
import ExerciseImageComponent from './exercise_image_component';

export default function ExerciseListItem(props) {
    const {
        classes, currentGroupExercise, exercise, handleExerciseAddClick,
        handleExerciseImageClick, handleExerciseClick, hidden,
        index, isGiphyView, mode, setExercises, setWorkout, showAuthor, 
        showStars, showWorkoutAttrs, workout, workoutPerform
    } = props;

    const config = useContext(ConfigContext);
    const {currentWorkout, user} = useContext(UserContext);
    const [addState, setAddState] = useState('none');
    let { groupExerciseId } = useParams();

    // ToDo: only allow setting the first exercise which doesn't have a time set.
    // Must they be done in order, or can the user skip around?

    const handleQuickAdd = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (handleExerciseAddClick) {
            handleExerciseAddClick(index);
            setAddState('added');
            return;
        }

        if (!currentGroupExercise?.id && !currentWorkout?.id) {
            return null;
        }

        setAddState('adding');

        let urlStub = '';
        let data = {};

        if (groupExerciseId) {
            urlStub = `exercises/${groupExerciseId}/add_segment`;
            data = {segment_id: exercise.id};
        } else if (currentWorkout?.id) {
            urlStub = `workouts/${currentWorkout.id}/add_exercise`;
            data = {exercise_id: exercise.id};
        } else if (workout) {
            // Within the context of :workout/edit
            urlStub = `workouts/${workout.id}/add_exercise`;
            data = {exercise_id: exercise.id};
        } else {
            //ToDo: Sentry: ExerciseListItem: Bad state for quick Add")
            return;
        }

        API.post(`${config.apiBase}/${urlStub}`, JSON.stringify(data))
            .then(result => {
                    SwsAnalytics.event({
                        category: currentGroupExercise?.id ? 'GroupExercise' : 'Workout',
                        action: 'Add Exercise Quick'
                    });

                    if (workout && setWorkout) {
                        setWorkout(result);
                        setExercises(result.exercises);
                    }
                    setAddState('added');
                }
            )
            .catch(error => {
                setAddState('none');
                alert(error.message);
            });
    };

    const handleImageClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (handleExerciseImageClick) {
            handleExerciseImageClick(index);
        } else if (handleExerciseClick) {
            handleExerciseClick(index);
        }
    };

    const renderAddButton = () => {
        let addIcon = <span className="add-icon"><FontAwesomeIcon icon={faPlusCircle}/></span>;

        if (addState === 'adding') {
            addIcon = <span className="adding-icon"><FontAwesomeIcon icon={faPlusCircle}/></span>
        } else if (addState === 'added') {
            addIcon = <span className="check-icon"><FontAwesomeIcon icon={faCheck}/></span>;
            setTimeout(() => {
                setAddState('none');
            }, 500);
        }

        return (
            <Col xs={1} className="mt-2 align-self-center ps-0">
                <div className="my-auto">
                    <Link to="#" disabled={addState === 'adding'} onClick={(event) => {
                        handleQuickAdd(event);
                    }} className="add-item">
                        {addIcon}
                    </Link>
                </div>
            </Col>
        );
    }

    const renderImageComponent = () => {
        return (
            <ExerciseImageComponent
                {...props}
                handleImageClick={handleImageClick}
            />
        );
    }

    if (!exercise) return null;

    let buttons = null;

    if (['index', 'profile', 'show'].includes(mode) && (groupExerciseId || currentWorkout)) {
        buttons = renderAddButton();
    } 

    if (isGiphyView) {
        return (
            <Col key={`exer-${index}`} xs={6} md={4} xl={3} className="p-0">
                <div className="cursor-pointer" onClick={() => {
                    handleExerciseClick(index)
                }}>
                    <ExerciseCard imageComponent={renderImageComponent()} index={index} exercise={exercise}>
                        <div className="symbol-card-top-right" style={{zIndex: '500'}}>
                            {buttons}
                        </div>
                    </ExerciseCard>
                </div>
            </Col>
        );
    } else {
        return (
            <ExerciseListItemRowRenderer
                buttons={buttons}
                classes={classes}
                exercise={exercise}
                handleExerciseClick={() => handleExerciseClick(index)}
                hidden={hidden}
                imageComponent={renderImageComponent()}
                index={index}
                mode={mode}
                showAuthor={showAuthor}
                showStars={showStars}
                showWorkoutAttrs={showWorkoutAttrs}
                user={user}
                workout={workout}
                workoutPerform={workoutPerform}
            />
        );
    }
}