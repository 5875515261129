import React, { useContext, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { ConfigContext } from "../../utils/config_context";
import ApiLoadingWrapper from "../common/api_loading_wrapper";
import AddButton from "../common/add_button";
import ProfileImageWrapper from "../users/profile_image_wrapper";
import { UserService } from "../../services/user_service";
import useSWR from "swr";
import { SwrUtils } from "../../utils/swr_utils";
import { DirectUpload } from "@rails/activestorage";
import * as Sentry from "@sentry/browser";
import { SwsAnalytics } from "../../services/sws_analytics";
import ObjectDefaults from "../../utils/object_defaults";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useActiveStorage } from "react-activestorage";

export default function ImageUploaderModal(props) {
    const { currentImageId, handleModalClose, handleSave, imagesUrl } = props;

    const config = useContext(ConfigContext);
    const [images, setImages] = useState([]);
    const [selectedImageId, setSelectedImageId] = useState(currentImageId);
    const [uploadError, setUploadError] = useState('');
    const [file, setFile] = useState(null);
    const imageForm = useRef(null);
    const cloudUpload = <FontAwesomeIcon icon={faCloudUploadAlt} className="px-1" />

    const { error, isLoading, mutate } =
        useSWR((config.apiBase && imagesUrl) ? `${config.apiBase}${imagesUrl}` : null,
            SwrUtils.authFetcher, {
            ...SwrUtils.stdOptions, onSuccess: (data) => {
                setImages(data)
            }
        });

    const activeStorageCallback = async ({ blob, error }) => {
        setUploadError(error?.message);
        setFile(undefined);
        await imageForm.current?.reset();

        const result = await API.post(`${config.apiBase}${imagesUrl}`, { ...blob })
            .catch((error) => {
                setUploadError(error?.message);
                return;
            });

        setSelectedImageId(result.id);
        mutate();
    };

    const authToken = UserService.authToken;
    const requestHeaders = { 'Authorization': authToken };

    const handleSetFile = (file) => {
        const newImage = Object.assign({}, images.pop(), {
            thumb: URL.createObjectURL(file),
            aasm_state: 'uploading'
        });

        setImages([...images, newImage])

        // This triggers the useActiveStorage
        setFile(file);
    }

    const { uploading, progress } = useActiveStorage(
        file,
        activeStorageCallback,
        requestHeaders,
        `${config.apiHost}/rails/active_storage/direct_uploads`
    );

    function handleCancel() {
        handleModalClose();
    }

    function handleDone() {
        if (currentImageId !== selectedImageId) {
            const selectedImage = images.find(image => image.id === selectedImageId);
            handleSave(selectedImage);
        }
        handleModalClose();
    }

    const imgClasses = "img-responsive align-middle w-100 card-img";

    const mappedItems = images?.map((image, index) => {
        if (!image.thumb) return null;

        let imageSrc = image.thumb;
        if (imageSrc && !imageSrc.match(/http/)) {
            imageSrc = `${config.s3Basepath}/${imageSrc}`;
        }

        return (
            <ProfileImageWrapper index={index} key={`image-${image.id}-wrapper`}>
                {image.aasm_state === 'uploading' &&
                    <>
                        <img alt={`images-${image.id}`} className={imgClasses} src={imageSrc} />
                        <div className="card-icon-bottom-right fadeInAndOut" style={{ background: 'black' }}>
                            {cloudUpload}
                        </div>
                    </>
                }
                {image.aasm_state !== 'uploading' &&
                    <img alt={`images-${image.id}`}
                        className={`${imgClasses} ${image.id === selectedImageId ? 'selected' : ''}`}
                        src={imageSrc}
                        onClick={() => { setSelectedImageId(image.id); }}
                    />
                }
            </ProfileImageWrapper>
        );
    });

    const handleClick = () => {
        setUploadError(null);
        setImages([...images, ObjectDefaults.image2()]);
        imageForm.current.imageInputField.click();
    }

    /* The old way
    function handleImageUpload(file) {

        if (file?.size > (config.maxFileSizes.userImageMb * 1024 * 1024)) {
            setUploadError(`Filesize limited to ${config.maxFileSizes.userImageMb} mb`);
            return false;
        }

        const authToken = UserService.authToken;

        if (!authToken) {
            setUploadError("Please (re)login to continue.");
            return;
        }

        const requestHeaders = { 'Authorization': authToken };

        return new Promise((resolve, reject) => {
            const upload = new DirectUpload(
                file,
                `${config.apiHost}/rails/active_storage/direct_uploads`,
                this,
                requestHeaders
            );
            const newImage = Object.assign({}, images.pop(), {
                thumb: URL.createObjectURL(file),
                aasm_state: 'uploading'
            });

            setImages([...images, newImage])

            SwsAnalytics.event({
                category: 'Image',
                action: 'Upload'
            });

            upload.create((error, blob) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(blob);
                }
            });
        }).then(blob => {
            // API call to create ExerciseVideo
            SwsAnalytics.event({
                category: 'Image',
                action: 'Create'
            });
            return API.post(`${config.apiBase}${imagesUrl}`, { ...blob });

        }).then(() => mutate()
        ).catch(error => {
            SwsAnalytics.event({
                category: 'UserImageUpload',
                action: `Error: ${error?.message}`
            });
            setUploadError(error?.message);
        });
    }
        */

    const renderImageUploadButton = () => {
        return (
            <Form ref={imageForm}>
                <AddButton clickHandler={handleClick} />
                <Form.Control
                    type="file"
                    style={{ display: 'none' }}
                    disabled={uploading}
                    id="imageInputField"
                    onChange={e => {
                        handleSetFile(e.target.files.item(0))
                        //handleImageUpload(e.target.files.item(0))
                    }}
                />
            </Form>
        );
    }

    const title = (error || uploadError) ? 'Error' : 'Select or Add Image';
    const errorMessage = (error || uploadError) ? 'An error has occurred, please reload page' : '';

    return (
        <>
            <Modal id="image-selection-modal" animation={false} show={true}
                onHide={() => handleCancel()}
            >
                <Modal.Header>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                    <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            handleCancel()
                        }} />
                </Modal.Header>
                <Modal.Body>
                    <ApiLoadingWrapper error={error} isLoading={isLoading} />
                    {errorMessage &&
                        <Row>
                            <Col xs={12}>{errorMessage}</Col>
                        </Row>
                    }
                    {!errorMessage &&
                        <>
                            <Row>
                                {mappedItems}
                                {uploadError?.length > 0 &&
                                    <Col xs={12}>
                                        <div className="d-flex w-100 pt-2 justify-content-center text-danger">
                                            {uploadError}
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    {progress && (<div>{progress.loaded} of {progress.total}</div>)}
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    {!error && !uploadError && renderImageUploadButton()}
                    <Button variant="primary" onClick={handleDone}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}