import React, { useRef } from "react";
import { Col, Form } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';

const WrappedTextArea = (props) => {
    const { defaultValue, handleChange, name, placeholder, maxLength, mode, myKey, readOnly } = props;
    const style = { width: '100%', border: 'none' };
    const textareaRef = useRef(null);

    return (
        <Col>
            <Form.Group controlId="formBasicText1">
                {['edit', 'new'].includes(mode) &&
                    <Form.Label>
                        <div className="form-section">Description</div>
                    </Form.Label>
                }
                <div className="px-1">
                    <TextareaAutosize
                        key={myKey}
                        className="textarea"
                        placeholder={placeholder}
                        defaultValue={defaultValue || ''}
                        onChange={handleChange}
                        name={name}
                        minRows="2"
                        maxLength={maxLength || 512}
                        style={style}
                        readOnly={readOnly}
                        ref={textareaRef}
                        wrap="soft"
                    />
                </div>
            </Form.Group>
        </Col>
    );
};

export default WrappedTextArea;