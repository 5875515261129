/*
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Icon, InlineIcon} from "@iconify/react";
import youtubePlay from '@iconify/icons-fa/youtube-play';
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import ExerciseAnimatedImage from "./exercise_animated_image";

const ExerciseCard = (props) => {
    let navigate = useNavigate();
    const {animate, children, exercise, imageComponent, index, showUsername} = props;

    /*
    const spinner = <div className="d-flex w-100 justify-content-center">
        <FontAwesomeIcon icon={faSpinner} size="3x"></FontAwesomeIcon>
    </div>;

    let image = exercise.standard_animation;
    if (image) {
        image = image.match(/http/) ? image : `${config.s3Basepath}/${image}`;
    }
     */

    const thisKey = index ? `ce-${index}` : '1';
    const myImageComponent = imageComponent ? imageComponent : (
        <ExerciseAnimatedImage size="sm" animate={animate} exercise={exercise} classes='card-img'/>
    );

    const handleUserFilter = (event, props) => {
        event.stopPropagation();
        event.preventDefault();

        const {exercise, location} = props;
        const newUrl = `/search/exercises?user_slug=${exercise.user_slug}`

        if (newUrl !== location.pathname) {
            navigate(newUrl);
        }
    };

    return (
        <div className="card border-light" key={thisKey}>
            {myImageComponent}
            <div className="card-img-overlay pt-0 exercise-card">
                <div className="text-center">
                    <div className="text-white text-shadow">
                        {exercise.title}
                    </div>
                    {showUsername &&
                    <div className="text-card-bottom">
                        <div className="text-primary" onClick={(event) => handleUserFilter(event, props)}>
                            {exercise.username}
                        </div>
                    </div>
                    }
                    {children}
                </div>
            </div>
        </div>
    );
};

/*
<img src={image}
     className="img-responsive w-100 card-img"
     loader={spinner}
     alt="Exercise image"/>

I felt that the use of the link & youtube icons were confusing against the context of the workouts

const renderLink = (link) => {
    if (link.match(/youtu/)) {
        return (
            <div style={{position: "absolute", right: ".3rem", bottom: ".3rem", color: "red"}}>
                <Icon icon={youtubePlay}/>
            </div>
        );
    } else {
        return (
            <div style={{position: "absolute", right: ".3rem", bottom: ".3rem", color: "yellow"}}>
                <FontAwesomeIcon icon={faLink}/>
            </div>
        )
    }
};
 */

export default ExerciseCard;
